import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Arb from "./InstalliionArb";
import Eng from "./InstalliionEng";
import Krd from "./InstalliionKrd";

export default function InstalliionDetails() {
  const Language = useSelector((state) => state.Language);

  function HeaderRender() {
    if (Language.language === "English") return <Eng />;
    else if (Language.language === "Kurdish") return <Krd />;
    else if (Language.language === "Arabic") return <Arb />;
  }
  return <>{HeaderRender()}</>;
}
