import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

export default function ArFooter() {
  return (
    <>
      <footer className="footer-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <Link to="/" className="logo">
                  <img src="../assets/img/pzlogow.png" alt="" />
                </Link>
                <p style={{ color: "white" }}>
                  لتوفير وتركيب المسابح واكواتيك المائية، هي فريق مكون من أعضاء
                  ذوي خبرة عالية، مبدعين ومهندسين متخصصين في تخطيط، تصميم، بناء
                  وتركيب مسابح فاخر، ساونا، غرف بخار، جاكوزي (حوض ساخن) ، حوض
                  بارد، حمام تركي و نافورة في إقليم كوردستان والعراق على نطاق
                  واسع.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3>خدماتنا </h3>
                <ul className="import-link">
                  <li>
                    <Link to="/services/Consultancy"> خدمات استشارية </Link>
                  </li>
                  <li>
                    <Link to="/services/archs">خدمات التصميم والمعمارية</Link>
                  </li>
                  <li>
                    <Link to="/services/installion/main">
                      خدمات انشاء والتركيب
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/testing">خدمات الاختبار والتشغيل</Link>
                  </li>
                  <li>
                    <Link to="/services/maintenance">
                      {" "}
                      خدمات الصيانة و القطع الغيار{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3>لينك سريع</h3>
                <ul className="import-link">
                  <li>
                    <Link to="/about"> نبذة عنا</Link>
                  </li>
                  <li>
                    <Link to="/media/photo">صور</Link>
                  </li>
                  <li>
                    <Link to="/media/video">فيديوهات</Link>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/poolzone1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://instagram.com/pool_zone?igshid=MzRlODBiNWFlZA=="
                      target="_blank"
                      rel="noreferrer"
                    >
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/@poolzone-dv4ve/videos"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Youtube
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3>Contact us</h3>
                <ul className="address">
                  <li className="location">
                    <span style={{ color: "white" }}>
                      شارع سالم، مقابل رئاسة بلدية السليمانية، قريب جسر خسروخال
                      <br />
                      محافظة السليمانية، اقليم كردستان، العراق
                    </span>{" "}
                  </li>
                  <li>
                    <i className="bx bxs-envelope" />
                    <a href="mailto:info@poolzone.co">info@poolzone.co</a>
                  </li>
                  <li>
                    <i className="bx bxs-phone-call" />
                    <a href="tel:+964-(770)-333-5151">+964 (770) 333-5151</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
