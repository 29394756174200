/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
import React, { useEffect } from "react";

export default function Krd() {
  useEffect(() => {
    window.$(".popup-youtube, .popup-vimeo").magnificPopup({
      disableOn: 300,
      type: "iframe",
      mainClass: "mfp-fade",
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,
    });
  }, []);

  return (
    <>
      <div>
        <div>
          {/* Start Page Title Area */}
          <div className="page-title-area bg-1">
            <div className="container">
              <div className="page-title-content">
                <h2> ڤیدیۆ</h2>
              </div>
            </div>
          </div>
          {/* End Page Title Area */}
          {/* Start Gallery Area */}
          <section className="gallery-column-two  ptb-100">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="video-area pb-100">
                    <div className="container">
                      <div className="video-content">
                        <img
                          src="../../assets/images/gallery/Video/v2.jpeg"
                          alt=""
                        />
                        <div className="video-btn-2">
                          <a
                            href="https://www.youtube.com/watch?v=YY30hEkIgAs"
                            className="popup-youtube"
                          >
                            <i className="flaticon-play-button" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="video-area pb-100">
                    <div className="container">
                      <div className="video-content">
                        <img
                          src="../../assets/images/gallery/Video/v3.jpeg"
                          alt=""
                        />
                        <div className="video-btn-2">
                          <a
                            href="https://www.youtube.com/watch?v=VS0cK9ZLacI"
                            className="popup-youtube"
                          >
                            <i className="flaticon-play-button" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="video-area pb-100">
                    <div className="container">
                      <div className="video-content">
                        <img
                          src="../../assets/images/gallery/Video/v4.jpeg"
                          alt=""
                        />
                        <div className="video-btn-2">
                          <a
                            href="https://www.youtube.com/watch?v=W-SrP9KRD48"
                            className="popup-youtube"
                          >
                            <i className="flaticon-play-button" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="video-area pb-100">
                    <div className="container">
                      <div className="video-content">
                        <img
                          src="../../assets/images/gallery/Video/v5.jpeg"
                          alt=""
                        />
                        <div className="video-btn-2">
                          <a
                            href="https://www.youtube.com/watch?v=ix38JydsG7w"
                            className="popup-youtube"
                          >
                            <i className="flaticon-play-button" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="video-area pb-100">
                    <div className="container">
                      <div className="video-content">
                        <img
                          src="../../assets/images/gallery/Video/v6.jpeg"
                          alt=""
                        />
                        <div className="video-btn-2">
                          <a
                            href="https://www.youtube.com/watch?v=98yzNi5KPLU"
                            className="popup-youtube"
                          >
                            <i className="flaticon-play-button" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="video-area pb-100">
                    <div className="container">
                      <div className="video-content">
                        <img
                          src="../../assets/images/gallery/Video/v7.jpeg"
                          alt=""
                        />
                        <div className="video-btn-2">
                          <a
                            href="https://www.youtube.com/watch?v=_NSMl8SEZ5s"
                            className="popup-youtube"
                          >
                            <i className="flaticon-play-button" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="video-area pb-100">
                    <div className="container">
                      <div className="video-content">
                        <img
                          src="../../assets/images/gallery/Video/v8.jpeg"
                          alt=""
                        />
                        <div className="video-btn-2">
                          <a
                            href="https://www.youtube.com/watch?v=u-_r0ChhIS4"
                            className="popup-youtube"
                          >
                            <i className="flaticon-play-button" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="video-area pb-100">
                    <div className="container">
                      <div className="video-content">
                        <img
                          src="../../assets/images/gallery/Video/v9.jpeg"
                          alt=""
                        />
                        <div className="video-btn-2">
                          <a
                            href="https://www.youtube.com/watch?v=v_Vo3UtSkgo"
                            className="popup-youtube"
                          >
                            <i className="flaticon-play-button" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="video-area pb-100">
                    <div className="container">
                      <div className="video-content">
                        <img
                          src="../../assets/images/gallery/Video/v10.jpeg"
                          alt=""
                        />
                        <div className="video-btn-2">
                          <a
                            href="https://www.youtube.com/watch?v=YDCRGIvt52E"
                            className="popup-youtube"
                          >
                            <i className="flaticon-play-button" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End Gallery Area */}
        </div>
      </div>
    </>
  );
}
