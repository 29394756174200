import Header from "./Components/Header/Index";
import { Route, Routes, Navigate } from "react-router-dom";
import Index from "./Pages/Home/Index";
import About from "./Pages/About/Index";
import Quality from "./Pages/Quality/Index";
import Photo from "./Pages/Media/Photo/Index";
import Video from "./Pages/Media/Video/Index";

import Contact from "./Pages/Contact/Index";
import Footer from "./Components/Footer/Index";
import Productlist from "./Pages/Products/ProductList/Productlist";
import { useDispatch, useSelector } from "react-redux";
import allActions from "./actions/allActions";
import { useEffect } from "react";
import ProductDetails from "./Pages/Products/ProductDetails/ProductDetails";
import ConsultancyIndex from "./Pages/Services/Consultancy/ConsultancyIndex";
import TestIndex from "./Pages/Services/Test/TestIndex";
import MaintenanceIndex from "./Pages/Services/Maintenance/MaintenanceIndex";
import InstallionIndex from "./Pages/Services/Installiion/InstalliionIndex";
import ArchsIndex from "./Pages/Services/Archs/ArchsIndex";
import References from "./Pages/Media/References/Index";

function App() {
  const Language = useSelector((state) => state.Language);
  const dispatch = useDispatch();
  useEffect(() => {
    window.$(".go-top").on("click", function () {
      window.$("html, body").animate({ scrollTop: "0" }, 500);
    });
    dispatch(allActions.LanguageAction.English());
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    // document.getElementsByTagName("body")[0].setAttribute("textAlign", "right");
  }, []);

  useEffect(() => {
    if (Language.language === "English") {
      import("./sources/bootstrap.min.css");
    } else if (Language.language === "Kurdish") {
      import("./sources/bootstrap.rtl.min.css");
      import("./sources/rtl.css");
    } else if (Language.language === "Arabic") {
      import("./sources/bootstrap.rtl.min.css");
      import("./sources/rtl.css");
    }
  }, [Language.language]);

  return (
    <>
      <Header />
      <Routes>
        <Route exact path="/" element={<Index />} />
        <Route path="/Productlist/:id" element={<Productlist />} />
        <Route path="/ProductDetails/:id" element={<ProductDetails />} />
        <Route path="/about" element={<About />} />
        <Route path="/quality" element={<Quality />} />
        <Route path="/services/Consultancy" element={<ConsultancyIndex />} />
        <Route path="/services/testing" element={<TestIndex />} />
        <Route path="/services/maintenance" element={<MaintenanceIndex />} />
        <Route
          path="/services/installion/:subService"
          element={<InstallionIndex />}
        />
        <Route path="/services/archs" element={<ArchsIndex />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/media/photo" element={<Photo />} />
        <Route path="/media/video" element={<Video />} />
        <Route path="/media/references" element={<References />} />
        <Route exact path="/*" element={<Index />} />
      </Routes>
      <Footer />
      <div className="go-top">
        <i className="bx bx-chevrons-up" />
        <i className="bx bx-chevrons-up" />
      </div>
    </>
  );
}

export default App;
