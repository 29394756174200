const Arabic = () => {
  return {
    type: "Arabic",
    // payload: lanObj,
  };
};
const English = () => {
  return {
    type: "English",
    // payload: lanObj,
  };
};
const Kurdish = () => {
  return {
    type: "Kurdish",
    // payload: lanObj,
  };
};

export default {
  Arabic,
  Kurdish,
  English,
};
