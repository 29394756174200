import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ArFooter from "./ArFooter";
import EngFooter from "./EngFooter";
import KrdFooter from "./KrdFooter";

export default function Index() {
  const Language = useSelector((state) => state.Language);

  function HeaderRender() {
    if (Language.language === "English") return <EngFooter />;
    else if (Language.language === "Kurdish") return <KrdFooter />;
    else if (Language.language === "Arabic") return <ArFooter />;
  }
  return <>{HeaderRender()}</>;
}
