import React from "react";

export default function Ar({ lan }) {
  return (
    <>
      <div>
        <div>
          <div
            className="page-title-area bg-services-3"
            style={{
              backgroundImage: `url("../../assets/images/services/Construction/SACS/SSB.jpeg")`,
            }}
          >
            <div className="container">
              <div className="page-title-content">
                <h2>خدمات انشاء والتركيب</h2>
              </div>
            </div>
          </div>
          {/* End Page Title Area */}
          {/* Start Product Details Area */}
          <section className="services-details-area pt-100 pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="services-details-content">
                    <div className="services-contents">
                      <div className="services-details-one">
                        <img src="../assets/images/services/ins.jpg" alt="" />
                      </div>
                      <h2 style={{ color: "#129dd9" }}>خدمات انشاء والتركيب</h2>
                    </div>
                    <div className="services-contents">
                      <p>
                        POOLZONE هو شريكك الوحيد لتنفيذ مشروعك من الفكرة إلى
                        الإكمال بتكلفة تنافسية. يتكون فريق البناء لدينا من
                        محترفين مؤهلين تأهيلا عاليا ومتحمسين لتقديم أفضل
                        النتائج.
                      </p>
                    </div>
                    <div className="services-contents">
                      <h2>غرف الساونا والبخار</h2>
                      <p>
                        تصميم وتركيب غرف الساونا والبخار الفاخرة ، وتوفير مواد
                        وخدمات و قطع الغيار لكل من المشاريع السكنية والتجارية
                        بأسعار تنافسية. ابدأ مع POOLZONE.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="services-sidebar">
                    <div className="services-category">
                      <img
                        src="../../assets/images/services/Construction/SACS/SS1.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                    <div className="services-category">
                      <img
                        src="../../assets/images/services/Construction/STCS/STS1.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                    <div className="services-category">
                      <img
                        src="../../assets/images/services/Construction/STCS/STS2.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* End Product Area */}
      </div>
    </>
  );
}
