import React from "react";

export default function Krd() {
  return (
    <>
      <div>
        <div>
          <div className="page-title-area bg-services-1">
            <div className="container">
              <div className="page-title-content">
                <h2>خزمەتگوزاری ڕاوێژکاری</h2>
              </div>
            </div>
          </div>
          {/* End Page Title Area */}
          {/* Start Product Details Area */}
          <section className="services-details-area pt-100 pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="services-details-content">
                    <div className="services-contents">
                      <div className="services-details-one">
                        <img src="../assets/images/services/cons.jpg" alt="" />
                      </div>
                      <h2>خزمەتگوزاری ڕاوێژکاری</h2>
                    </div>
                    <div className="services-contents">
                      <p>
                        ئێمە ڕووپێوی گشتگیر لە شوێنی کارەکەتدا ئەنجام دەدەین،
                        پێش دەستپێکردنی هەر کارێک گفتوگۆی تایبەتمەندی و
                        پێداویستیەکانت و دامەزراندنی پڕۆژەکەت لەگەڵدا دەکەین.
                        پاشان ئەندازیارەکانمان پلانی ورد ئامادە دەکەن، کە هەموو
                        شتێک لە قەبارە و شێوەی حەوزەکەوە تا قووڵایی و کاری
                        کۆنکرێتی و ڕووناکی و هەروەها هەر پێداویستییەکی تایبەت
                        لەخۆدەگرێت. هەمان پرۆسە لە کارەکانی تریشدا
                        بەکاردەهێنرێت.
                      </p>
                      <p>
                        ڕاوێژکارەکانمان ڕێنمایی دروست و دڵنیاکەرەوە پێشکەش دەکەن
                        سەبارەت بەو کێشە چاوەڕواننەکراوانەی کە لە هەر قۆناغێکی
                        پڕۆژەکاندا دێنەپێش وە هەروەها دەربارەی پرۆسەکانی
                        چاککردنەوەش.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* End Product Area */}
      </div>
    </>
  );
}
