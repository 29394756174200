import React from "react";

export default function Eng() {
  return (
    <>
      <div>
        <div>
          <div className="page-title-area bg-services-1">
            <div className="container">
              <div className="page-title-content">
                <h2>Consultancy Services</h2>
              </div>
            </div>
          </div>
          {/* End Page Title Area */}
          {/* Start Product Details Area */}
          <section className="services-details-area pt-100 pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="services-details-content">
                    <div className="services-contents">
                      <div className="services-details-one">
                        <img src="../assets/images/services/cons.jpg" alt="" />
                      </div>
                      <h2>Consultancy Services</h2>
                    </div>
                    <div className="services-contents">
                      <p>
                        We conduct a comprehensive site survey and speak with
                        you about your every desire for your project
                        installation before any work begins. Our specialist
                        engineers will then supply detailed plans, which include
                        everything from pool size and shape to depth, civil
                        works details and lighting, as well as any special
                        features and enclosures you choose to add, and the same
                        process is applied to the other facilities like sauna,
                        jacuzzi, steam room, cold pool, Turkish bath, fountain
                        ...etc.
                      </p>
                      <p>
                        Our consultants provide sound and secure advice on
                        unexpected issues that arise as the projects progresses
                        and the maintenance processes as well
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* End Product Area */}
      </div>
    </>
  );
}
