import React from "react";

export default function Arb() {
  return (
    <>
      <div dir="rtl">
        <div>
          <div className="page-title-area bg-services-1">
            <div className="container">
              <div className="page-title-content">
                <h2>خدمات استشارية</h2>
              </div>
            </div>
          </div>
          {/* End Page Title Area */}
          {/* Start Product Details Area */}
          <section className="services-details-area pt-100 pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="services-details-content">
                    <div className="services-contents">
                      <div className="services-details-one">
                        <img src="../assets/images/services/cons.jpg" alt="" />
                      </div>
                      <h2>خدمات استشارية</h2>
                    </div>
                    <div className="services-contents">
                      <p>
                        نجري مسحًا شاملاً للموقع ونتحدث معك عن رغبتك في تثبيت
                        مشروعك قبل بدء أي عمل. سيقوم مهندسينا بعد ذلك باستعداد
                        خطط مفصلة ، والتي تشمل كل شيء من حجم وشكل المسبح إلى
                        العمق و الاعمال و المدنية والإضاءة ، بالإضافة إلى أي
                        ميزات و المستلزمات خاصة. ويتم تطبيق نفس العملية على
                        الاعمال الأخرى.. الساونا والجاكوزي وغرفة البخار و الحوض
                        البارد والحمام التركي والنافورة.
                      </p>
                      <p>
                        يقدم مستشارونا المشورة السليمة والمطلوبة بشأن المشكلات
                        غير المتوقعة التي تنشأ مع تقدم المشاريع وعمليات الصيانة
                        أيضًا.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* End Product Area */}
      </div>
    </>
  );
}
