import React from "react";

export default function Eng() {
  return (
    <>
      <div>
        <div>
          <div
            className="page-title-area bg-services-3"
            style={{
              backgroundImage: `url("../../assets/images/services/Construction/FCS/FSB.jpeg")`,
            }}
          >
            <div className="container">
              <div className="page-title-content">
                <h2>Construction and Installation Services</h2>
              </div>
            </div>
          </div>
          {/* End Page Title Area */}
          {/* Start Product Details Area */}
          <section className="services-details-area pt-100 pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="services-details-content">
                    <div className="services-contents">
                      <div className="services-details-one">
                        <img src="../assets/images/services/ins.jpg" alt="" />
                      </div>
                      <h2 style={{ color: "#129dd9" }}>
                        Great Design Requires Expert Execution
                      </h2>
                    </div>
                    <div className="services-contents">
                      <p>
                        POOLZONE is your only partner to implement your project
                        from Concept to Completion with a Competitive Cost. Our
                        build team is made up of highly qualified professionals
                        who are passionate about delivering the best result.
                      </p>
                      <p></p>
                    </div>
                    <div className="services-contents">
                      <h2>Fountain</h2>
                      <p>
                        Fountain should be delight and amaze, we achieve this by
                        consulting our engineers on equipment and construction
                        process and adding to each new technology, modern hits,
                        fog, animated video, musical dancing, recessed lights
                        around the fountain. Get started with POOLZONE
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="services-sidebar">
                    <div className="services-category">
                      <img
                        src="../../assets/images/services/Construction/FCS/FS1.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>

                    <div className="services-category">
                      <img
                        src="../../assets/images/services/Construction/FCS/FS2.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* End Product Area */}
      </div>
    </>
  );
}
