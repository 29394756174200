import React from "react";

export default function Eng() {
  return (
    <>
      <div>
        <div>
          <div className="page-title-area bg-services-4">
            <div className="container">
              <div className="page-title-content">
                <h2>Test and Commissioning Services</h2>
              </div>
            </div>
          </div>
          {/* End Page Title Area */}
          {/* Start Product Details Area */}
          <section className="services-details-area pt-100 pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="services-details-content">
                    <div className="services-contents">
                      <div className="services-details-one">
                        <img src="../assets/images/services/test.jpg" alt="" />
                      </div>
                      <h2>Test and Commissioning Services</h2>
                    </div>
                    <div className="services-contents">
                      <p>
                        To ensure that we are doing the right setup of the
                        project and carried out at the highest standard, we will
                        follow up and partially test the project at each stage
                        of implementation and for every details.
                      </p>
                      <p>
                        For project delivery, we apply the final test, we train
                        the customers/operators on how to operate the entire
                        system.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="services-sidebar">
                    <div className="services-category">
                      <img
                        src="../assets/images/services/TCS/TCS1.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* End Product Area */}
      </div>
    </>
  );
}
