import React from "react";

export default function Eng() {
  return (
    <>
      <div>
        <div>
          <div className="page-title-area bg-services-2">
            <div className="container">
              <div className="page-title-content">
                <h2>Design & Architectural Services</h2>
              </div>
            </div>
          </div>
          {/* End Page Title Area */}
          {/* Start Product Details Area */}
          <section className="services-details-area pt-100 pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="services-details-content">
                    <div className="services-contents">
                      <h2>
                        A design service that matches your feelings and needs
                      </h2>
                    </div>
                    <div className="services-contents">
                      <p>
                        Our engineering department with creative engineers
                        specializes in bespoke design driven by your interests,
                        perceptions, and needs that is suited to your home,
                        villa, or commercial project.
                      </p>
                      <p>
                        And also supervising and monitoring the plan
                        implementation on the site by the engineers to ensure
                        you are getting the right setup for your property and to
                        be identical to the desired plan and design.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="services-sidebar">
                    <div className="services-category">
                      <img
                        src="../assets/images/services/Design/DS1.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                  </div>
                  <div className="services-sidebar">
                    <div className="services-category">
                      <img
                        src="../assets/images/services/Design/DS2.jpeg"
                        alt=""
                        style={{
                          height: "auto",
                          width: "auto",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* End Product Area */}
      </div>
    </>
  );
}
