import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import allActions from "../../actions/allActions";

const categoryJson = require("../../externals/category.json");
const subCategoryJson = require("../../externals/subCategory.json");

export default function EngHeader() {
  const dispatch = useDispatch();

  const onchangeLanguageHandler = (lan) => {
    if (lan === "eng") {
      dispatch(allActions.LanguageAction.English());
      document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");

      document.getElementsByTagName("html")[0].setAttribute("lan", "en");
    } else if (lan === "ar") {
      dispatch(allActions.LanguageAction.Arabic());
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");

      document.getElementsByTagName("html")[0].setAttribute("lan", "ar");
    } else if (lan === "krd") {
      dispatch(allActions.LanguageAction.Kurdish());
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");

      document.getElementsByTagName("html")[0].setAttribute("lan", "ar");
    }
  };

  const jQueryCustom = () => {
    "use strict";
    // Mean Menu JS
    window.$(".mean-menu").meanmenu({
      meanScreenWidth: "991",
    });
  };

  useEffect(() => {
    jQueryCustom();
  }, []);
  return (
    <>
      <header className="header-area">
        <div className="top-header">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-6">
                <ul className="header-left-content">
                  <li>
                    <a
                      href="https://www.facebook.com/poolzone1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://instagram.com/pool_zone?igshid=MzRlODBiNWFlZA=="
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-instagram" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/@poolzone-dv4ve/videos"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-youtube" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="header-right-content">
                  <ul className="flag-area">
                    <li className="flag-item-top">
                      <span className="flag-bar">
                        <img
                          src="../../assets/img/flag/english.png"
                          alt=""
                          style={{ height: 20, width: 20 }}
                        />
                        <span>Language</span>
                        <i className="bx bx-chevron-down" />
                      </span>
                      <ul className="flag-item-bottom">
                        <li className="flag-item">
                          <button
                            className="flag-link"
                            onClick={() => onchangeLanguageHandler("eng")}
                            style={{
                              border: "none",
                              background: "none",
                              cursor: "pointer",
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            <img
                              src="../../assets/img/flag/english.png"
                              alt=""
                            />
                            English
                          </button>
                        </li>
                        <li className="flag-item">
                          <button
                            onClick={() => onchangeLanguageHandler("ar")}
                            className="flag-link"
                            style={{
                              border: "none",
                              background: "none",
                              cursor: "pointer",
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            <img src="../../assets/img/flag/arab.png" alt="" />
                            العربية
                          </button>
                        </li>
                        <li className="flag-item">
                          <button
                            className="flag-link"
                            onClick={() => onchangeLanguageHandler("krd")}
                            style={{
                              border: "none",
                              background: "none",
                              cursor: "pointer",
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            <img
                              src="../../assets/img/flag/kurdistan.png"
                              alt=""
                            />
                            کوردی
                          </button>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="navbar-area">
          <div className="mobile-nav">
            <div className="container-fluid">
              <Link to="/" className="logo">
                <img
                  src="../../assets/img/pzlogo.png"
                  alt="Logo"
                  style={{ height: "60px" }}
                />
              </Link>
            </div>
          </div>

          <div className="main-nav">
            <div className="container-fluid">
              <nav className="navbar navbar-expand-md">
                <Link className="navbar-brand" to="/">
                  <img
                    src="../../assets/img/pzlogo.png"
                    alt="Logo"
                    style={{ height: "60px", border: "0px" }}
                  />
                </Link>
                <div className="collapse navbar-collapse mean-menu">
                  <ul className="navbar-nav m-auto">
                    <li className="nav-item">
                      <Link
                        to={"/"}
                        className="nav-link active"
                        style={{ fontSize: 20, fontWeight: "bold" }}
                      >
                        Home
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        style={{ fontSize: 20, fontWeight: "bold" }}
                      >
                        Services
                        <i className="bx bx-chevron-down" />
                      </Link>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <Link
                            to={"/services/Consultancy"}
                            className="nav-link"
                          >
                            Consultancy Services
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/services/archs"} className="nav-link">
                            Design & Architectural Services
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to={"/services/installion/main"}
                            className="nav-link"
                          >
                            Construction & Installation Services
                            <i className="bx bx-chevron-right" />
                          </Link>
                          <ul className="dropdown-menu">
                            <li className="nav-item">
                              <Link
                                to={"/services/installion/swimmingpool"}
                                className="nav-link"
                              >
                                Swimming Pool
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to={"/services/installion/souna"}
                                className="nav-link"
                              >
                                Sauna & Steam Rooms
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to={"/services/installion/jacuzzi"}
                                className="nav-link"
                              >
                                Custom Jacuzzi (Hot Pool) & Cold Pool
                              </Link>
                            </li>{" "}
                            <li className="nav-item">
                              <Link
                                to={"/services/installion/turkishbath"}
                                className="nav-link"
                              >
                                Turkish Bath
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to={"/services/installion/fountain"}
                                className="nav-link"
                              >
                                Fountain
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="nav-item">
                          <Link to={"/services/testing"} className="nav-link">
                            Test & Commissioning Services
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to={"/services/maintenance"}
                            className="nav-link"
                          >
                            Maintenance & Replacement Part Services
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        style={{ fontSize: 20, fontWeight: "bold" }}
                      >
                        Products
                        <i className="bx bx-chevron-down" />
                      </Link>
                      <ul className="dropdown-menu">
                        {/* {ProductsNavRender()} */}
                        {categoryJson.map((item, pindex) => {
                          const filtered = subCategoryJson.filter((obj) => {
                            return obj.CategoryENG === item.CategoryENG;
                          });
                          // if (filtered.length)
                          return (
                            <li key={pindex} className="nav-item">
                              <Link
                                className="nav-link"
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                {item.CategoryENG}
                                <i className="bx bx-chevron-right" />
                              </Link>
                              <ul className="dropdown-menu">
                                {filtered.map((subItem, spindex) => {
                                  return (
                                    <li key={spindex} className="nav-item">
                                      <Link
                                        key={spindex}
                                        to={`/Productlist/${subItem.subCategoryENG}`}
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                        className="nav-link"
                                      >
                                        {subItem.subCategoryENG}
                                      </Link>
                                    </li>
                                  );
                                })}
                                {/* <li className="nav-item">
                                  <Link to={"/"} className="nav-link">
                                    Gallery Column Two
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link to={"/"} className="nav-link">
                                    Gallery Column Three
                                  </Link>
                                </li> */}
                              </ul>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        style={{ fontSize: 20, fontWeight: "bold" }}
                      >
                        Media
                        <i className="bx bx-chevron-down" />
                      </Link>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <Link to={"/media/photo"} className="nav-link">
                            Photo
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to={"/media/video"}>
                            Video
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to={"/media/references"}>
                            References
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/quality"}
                        className="nav-link"
                        style={{ fontSize: 20, fontWeight: "bold" }}
                      >
                        About Quality
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/about"}
                        className="nav-link"
                        style={{ fontSize: 20, fontWeight: "bold" }}
                      >
                        About Us
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to={"/contact"}
                        className="nav-link"
                        style={{ fontSize: 20, fontWeight: "bold" }}
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                  <div className="others-option">
                    <div className="call-us">
                      <i className="bx bx-phone-call" />
                      <span href="tel:+964-(770)-333-5151">
                        +964 (770) 333-5151
                      </span>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          <div className="others-option-for-responsive">
            <div className="container">
              <div className="dot-menu">
                <div className="inner">
                  <div className="circle circle-one" />
                  <div className="circle circle-two" />
                  <div className="circle circle-three" />
                </div>
              </div>
              <div className="container">
                <div className="option-inner">
                  <div className="others-option justify-content-center d-flex align-items-center">
                    <div className="call-us">
                      <i className="bx bx-phone-call" />
                      <a href="tel:+1-(514)-312-5678">+1 (514) 312-5678</a>
                    </div>
                    <div className="option-item">
                      <i className="search-btn bx bx-search" />
                      <i className="close-btn bx bx-x" />
                      <div className="search-overlay search-popup">
                        <div className="search-box">
                          <form className="search-form">
                            <input
                              className="search-input"
                              name="search"
                              placeholder="Search"
                              type="text"
                            />
                            <button className="search-button" type="submit">
                              <i className="bx bx-search" />
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="cart-icon">
                      <a href="shopping-cart.html">
                        <i className="bx bx-cart" />
                        <span>0</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
