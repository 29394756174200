import React from "react";

export default function Krd({ lan }) {
  return (
    <>
      <div>
        <div>
          <div
            className="page-title-area bg-services-3"
            style={{
              backgroundImage: `url("../../assets/images/services/Construction/PCS/PSB.jpeg")`,
            }}
          >
            <div className="container">
              <div className="page-title-content">
                <h2>خزمەتگوزاری دروستکردن و دامەزراندن</h2>
              </div>
            </div>
          </div>
          {/* End Page Title Area */}
          {/* Start Product Details Area */}
          <section className="services-details-area pt-100 pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="services-details-content">
                    <div className="services-contents">
                      <div className="services-details-one">
                        <img src="../assets/images/services/ins.jpg" alt="" />
                      </div>
                      <h2 style={{ color: "#129dd9" }}>
                        خزمەتگوزاری دروستکردن و دامەزراندن
                      </h2>
                    </div>
                    <div className="services-contents">
                      <p>
                        POOLZONE تەنها هاوبەشی تۆیە بۆ جێبەجێ کردنی پڕۆژەکەت لە
                        بیرۆکەوە بۆ تەواوکردن بە تێچووی کێبڕکێیانە. تیمی
                        بیناسازیمان لە کەسانی پسپۆڕی شارەزا پێکهاتووە کە خولیای
                        گەیاندنی باشترین ئەنجامیان هەیە.
                      </p>
                    </div>
                    <div className="services-contents">
                      <h2>مەلەوانگە</h2>
                      <p>
                        گشت کارەکانی دانانی بۆری و پاڵاوتن و میکانیکی و تەکنیکی
                        بۆ مەلەوانگەی داخراو و کراوە ئەنجام دەدەین.
                      </p>
                      <p>
                        دروستکردنی هەر مەلەوانگەیەک مەرجی تایبەتی خۆی هەیە بەپێی
                        ئەرزی شوێنەکە و شێوە و قەبارە و چەند هۆکارێکی تر، بۆیە
                        پێویستی بە لێکۆڵینەوەی زانستی ورد هەیە بۆ دانانی سیستمی
                        بۆری و سیستمی میکانیکی.
                      </p>

                      <p>
                        بۆ باشترین ئەنجامی جێبەجێکردن، وە بۆ بەدەستهێنانی کاری
                        ستاندارد بەبێ کێشە و سەرئێشە، با تەنها لایەنێک پرۆژەی
                        مەلەوانگەکەت بۆ جێبەجێ بکات تاکو ڕادەست کردنی کلیل...
                        POOLZONE هاوبەشی متمانەپێکراوی تۆیە بۆ هەڵگرتنی
                        بەرپرسیارێتی
                      </p>
                      <p style={{ paddingRight: "25px" }}>
                        - پلاندانان
                        <br /> - ديزاين
                        <br /> - هەڵکەندنی زەویەکە <br /> - دانانی سیستمی بۆری
                        <br /> - کاری کۆنکرێتی
                        <br /> - لەبغ کردن
                        <br /> - چارەسەری لێچوونی ئاو <br /> - کاشی کردن <br />{" "}
                        - باڵانس تانک بۆ ئاو، ژووری میکانیکی و دانانی پێداویستی
                        و سیستمی میکانیکی <br /> - تەواوکاری و ئیکسسوارات
                      </p>
                    </div>
                    <div className="services-contents">
                      <p
                        style={{
                          fontSize: 24,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        کارە میکانیکیەکان
                      </p>
                      <p>
                        گشت مەلەوانگەکان لەسەر یەک بنەما دروست دەکرێن و کاردەکەن
                        بە واتایەکی تر بەبێ گوێدانە شێوە و قەبارە و
                        تایبەتمەندییەکانی تر؛ کارکردنی گشت مەلەوانگەکان لە
                        بنەڕەتدا هاوشێوەیە. هەموویان تێکەڵەیەک لە چارەسەری
                        کیمیایی و پاڵاوتن بەکاردەهێنن بۆ ئەوەی ئاوەکە پاک و
                        تەندروست بمێنێتەوە لە هەموو کاتێکدا.
                        <br />
                        <br />
                        چارەسەرکردنی ئاو بۆ مەلەوانگەکان دوو تەحەدای هەیە:{" "}
                        <p style={{ paddingRight: "25px" }}>
                          {" "}
                          - پاکوخاوێنی بۆ مەلەوانان <br />- ڕوونی ئاوەکە
                        </p>
                      </p>
                      <p>
                        مەبەست لە پاڵاوتنی مەلەوانگە چارەسەرکردنی ئاوی مەلەوانگە
                        بەجۆرێک کە ئاستی کلۆر و پی ئێچی ئاسایی بێت. هەروەها کار
                        لەسەر لابردنی توخمە ناسەلامەتەکانی وەک بەکتریا و
                        قەوزەکان دەکات بۆ ئەوەی ئاوەکە سەلامەت بێت بۆ ئەوەی
                        هەمووان چێژ لە مەلەوانگەکە وەربگرن .
                      </p>

                      <p>
                        سیستمی پاڵاوتن لە چەند بەشێک پێکدێت کە هەریەکەیان ڕۆڵێکی
                        گرنگ دەگێڕن:
                        <br />
                        <p style={{ paddingRight: "25px" }}>
                          <strong style={{ fontSize: 20 }}>
                            -پەمپ وبۆردی کارەبایی:
                          </strong>
                          <p style={{ paddingRight: "50px" }}>
                            <strong> - پەمپ:</strong> بەکاردێت بەمەبەستی
                            سووڕانەوەی ئاو بۆ ڕیسایکلکردن و پاککردنەوە. لە ٣
                            توخم پێکهاتووە: پێش فلتەرێک بۆ نەهێشتنی پیسی درشت،
                            "بۆدی"یەک کە پێکهاتووە لە تۆرباینێک کە ئاوەکە
                            دەسوڕێتەوە و ماتۆڕێک (یەک فەیس یان سێ فەیس).
                            <br /> <strong>- بۆردی کارەبایی:</strong>  بەستنەوەی
                            پەمپەکە بە سەرچاوەی کارەباییەوەوە هەروەها بەستنەوە و
                            کۆنترۆڵ کردنی زۆرێک لە پێکهاتەکانی ژووری میکانیکی.
                            تایمەرێکی تێدایە کە ڕێگەت پێدەدات کاتی فلتەر کردن
                            بەرنامەی بۆ دابنێیت.
                            <br />
                            <strong>- تایمەری فیلتەر کردن</strong>:  بۆ ئەوەی بە
                            شێوەیەکی ئۆتۆماتیکی سیستمی پاڵاوتن دەستپێبکات. کاتی
                            فلتەرکردن ڕێژەیی دەبێت لەگەڵ پلەی گەرمی ئاو.
                          </p>
                        </p>
                        <p style={{ paddingRight: "25px" }}>
                          <strong style={{ fontSize: 20 }}>- فلتەر:</strong>
                           ڕێگە بە جیاکردنەوەی پیسی دەدات لەو ئاوەی کە تێیدایە.
                          فلتەر کردن ڵێڵی ئاوەکە لەناو دەبات و ڕوونی دەکاتەوە و
                          کوالیتییەکەی باشتر دەکات؛ فلتەری لم (چەند چینێک لە جۆر
                          و قەبارەی جیاواز لە لمی تایبەت) بە تێپەڕاندنی ئاوی پیس
                          لە ڕێگەی سیستمی پاڵاوتنی لمەوە کاردەکەن و بە
                          تەنۆلکەکاندا تێپەڕ دەبێت، ئاوی پاک دەگەڕێتەوە بۆ
                          مەلەوانگەکە. هەروەها دەتوانیت لەبری لمی تایبەت لمی
                          شووشەیی بەکاربهێنرێت. فلتەرەکانی لمی شووشەیی باشتر
                          فلتەر دەکەن و کەمتر پێویستیان بە شۆردنی خۆیی فلتەرەکە
                          هەیە و بۆ ساڵانی زیاتر کار دەکات .
                        </p>
                        <p style={{ paddingRight: "25px" }}>
                          <strong style={{ fontSize: 20 }}>
                            - سیستمی هایدرۆلیکی :
                          </strong>
                          <p style={{ paddingRight: "50px" }}>
                            <strong> شێوازی سیستمی سەرڕێژ کردن:</strong> بە
                            پێچەوانەی مەلەوانگەی جۆری سیستمی سکیمەر(کە ئاو لە
                            لادیوارەکانەوە دەگەڕێتەوە)، سیستمی سەرڕێژ کردن
                            ئاوەکە لە جووڵەی بەردەوامدا دەهێڵێتەوە: ئاوی ڕژاو لە
                            ڕێگەی تۆڕێکەوە دەڕواتە ناو ئەو کەناڵەی کەلە دەوری
                            مەلەوانگەکەدایە؛ ئەم ئاوە دەچێتە سەر تانکی باڵانس و
                            پاشان فلتەرەکە وە دواتر دەگەڕێتەوە بۆ مەلەوانگەکە
                            <br /> <strong>- بۆری و سیستمی ئەرزی:</strong> ئاوی
                            مەلەوانگەکە لە ڕێگەی ئەم سیستمە ئەرزیەوە دەوران
                            دەکات بۆ پاڵاوتن و گەرم کردن و گەڕانەوەی بۆ
                            مەلەوانگەکە،ئەم سیستمە دەبێت بەرگەی فشاری بەرز بگرێت
                            کە تایبەتە بە سیستمی مەلەوانگە .
                            <br />
                            <strong>- نۆزڵی پڕکردنەوە:</strong> پێویستە بۆ
                            گەڕانەوەی ئاوی فلتەرکراو بۆ مەلەوانگە .
                          </p>
                        </p>
                        <p style={{ paddingRight: "25px" }}>
                          <strong style={{ fontSize: 20 }}>
                            - گەرم کردنی ئاوی مەلەوانگە:{" "}
                          </strong>
                          هیتەری تایبەت ئاوی مەلەوانگەکە گەرم دەکات و لەڕێگەی
                          پەمپەوە ئاوە گەرم کراوەکە دەچێتەوە بۆ ناو مەلەوانگەکە
                          .
                          <p style={{ paddingRight: "50px" }}>
                            - هیتەری کارەبایی
                            <br /> - هیتەری جۆری هیت پەمپ
                            <br />- هیتەری غاز
                          </p>
                        </p>
                        <p style={{ paddingRight: "25px" }}>
                          <strong style={{ fontSize: 20 }}>
                            - کۆنترۆڵکردنی هەوای دەوروبەر بە ئامێری نە‌هێشتنی شێ
                            و سیستمی هەواگۆڕکێ:{" "}
                          </strong>
                          سیستمی کۆنترۆڵکردنی هەوا لە هەر پڕۆژەیەکی مەلەوانگەی
                          داخراودا زۆر گرنگە بۆ ڕێگریکردن لە چڕبوونەوە و شێ و
                          پاشان ڕێگریکردن لە زیانێکی تێچووی زۆر بە هۆڵی
                          مەلەوانگەکەت، هەروەها دڵنیای دەدات لەوەی کە هۆڵی
                          مەلەوانگەکەت ژینگەیەکی خۆشگەزەرانە بۆ بەکارهێنەران.
                          <br />
                          <br />
                          <p style={{ paddingRight: "50px" }}>
                            -{" "}
                            <strong>
                              {" "}
                              کۆنترۆڵی ڕادەی شێی هۆڵی مەلەوانگەی داخراو:{" "}
                            </strong>
                            <br /> -{" "}
                            <strong>
                              {" "}
                              گەرم کردنی هۆڵی مەلەوانگەی داخراو:{" "}
                            </strong>
                            وەرگرتنەوەی وزە لە پرۆسەی نەهێشتنی شێ کە زۆرجار
                            دەتوانێت تەواوی هۆڵی مەلەوانگەکە گەرم بکات بە هامان
                            ئامێری نە‌هێشتنی شێ.
                            <br /> -{" "}
                            <strong>
                              {" "}
                              سارد کردنی هۆڵی مەلەوانگەی داخراو:{" "}
                            </strong>{" "}
                            کۆندێنسەردادەنرێت بۆ هۆڵی مەلەوانگەی داخراو بۆ سارد
                            کردنەوە لە مانگە گەرمەکاندا.
                          </p>
                        </p>
                        <p style={{ paddingRight: "25px" }}>
                          <strong style={{ fontSize: 20 }}>
                            - ماددە کیمیاییەکان بۆ پاککردنەوەی مەلەوانگە:{" "}
                          </strong>
                          ماددە کیمیاییەکانی مەلەوانگە، وەک کلۆر،بەکار دەهێنرێت
                          (بۆ نموونە لە مەلەوانگە، گەرماو/سپا، و یاریە ئاویەکان)
                          بۆ پاراستنی مەلەوانان لە بڵاوبوونەوەی میکرۆب و
                          ڕێگریکردن لە بڵاوبوونەوەی. ماددە کیمیاییەکانی تری وەک
                          پی ئێچ و ئەوانی تر یارمەتیدەرن لە پرۆسەی تەعقیمکردن بۆ
                          نموونە کۆنترۆڵکردنی کوالیتی ئاوەکە و پاک کردنەوەی لە
                          چەوری و قەوزەکان و هتد.
                          <br />
                          بەرهەمە کیمیاییەکانمان لەم جۆرانە پێکدێت:
                          <p style={{ paddingRight: "50px" }}>
                            - <strong>مەوادی تەعقیم (کلۆر): </strong>
                            تەعقیمکەری کلۆر بۆ پاکوخاوێنی. ئەم بەرهەمانە لە
                            شێوەکانی حەب و دەنکۆڵە و شلەدایە.
                            <br /> - <strong>ڕێکخەری پی ئێچ: </strong> بەرهەمی
                            شلەی ئەلکالی و ترشە نائۆرگانیکەکان لە شێوەی شلە و
                            دەنکۆڵەدا بۆ ڕێکخستنی پی ئێچ.
                            <br /> - <strong>
                              کۆئەگولانت-فلۆکۆلانتس:{" "}
                            </strong>{" "}
                            ئاوەکە ڕوون دەکاتەوە بە کۆکردنەوە و یەکگرتنی تەنۆلکە
                            زۆر وردەکانی ناو ئاوەکە، زەیتە ئۆرگانیەکان و
                            سیلیکاتەکان کە بە تەنها فلتەر ناتوانێت بیپاڵێوێت،
                            پاش یەکگرتنی ئەو تەنۆلکانە و دروست بوونی تەنی
                            گەورەتر دەتوانرێت لە فلتەرەکاندا لەناوببرێن.
                            <br /> - ماددە کیمیاییە تەواوکەرەکانی تر بۆ پاراستنی
                            مەلەوانگەکەت لە وەرزی زستاندا. ڕێگریکردن لە ڵێڵ
                            بوونی ئاوەکە و کلس کردن و هتد.
                          </p>
                        </p>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="services-sidebar">
                    <div className="services-category">
                      <img
                        src="../../assets/images/services/Construction/PCS/PS1.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>

                    <div className="services-category">
                      <img
                        src="../../assets/images/services/Construction/PCS/PS2.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                    <div className="services-category">
                      <img
                        src="../../assets/images/services/Construction/PCS/PS3.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>

                    <div className="services-category">
                      <img
                        src="../../assets/images/services/Construction/PCS/PS4.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                    <div className="services-category">
                      <img
                        src="../../assets/images/services/Construction/PCS/PS5.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>

                    <div className="services-category">
                      <img
                        src="../../assets/images/services/Construction/PCS/PS6.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* End Product Area */}
      </div>
    </>
  );
}
