import React from "react";

export default function Arb() {
  return (
    <>
      <div>
        <div
          className="page-title-area bg-1"
          style={{
            backgroundImage: `url("../assets/images/about/AboutBan.jpeg")`,
            // height: " 80vh",
            // backgroundPosition: "center",
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "center",
          }}
        >
          <div className="container">
            <div className="page-title-content">
              <h2> المشاريع المنفذة</h2>
            </div>
          </div>
        </div>

        {/* End Page Title Area */}
        {/* Start About Area */}
        <section className="about-area pt-100 pb-70">
          <div className="container">
            <div className="row ">
              <div className="col-lg-8">
                <div className="about-content">
                  <h2 className="top-title" style={{ fontSize: 25 }}>
                    المشاريع الرئيسية المنفذة
                  </h2>

                  <br />
                  <p>
                    - فندق تيتانيك
                    <br />
                    - فندق ميهراكو <br />
                    - فندق هاي كرست
                    <br />
                    - فندق رامادا (ساونا) <br />
                    - مسبح اتلانتيك
                    <br />
                    - مركز هاي كلاس
                    <br />
                    - تايتن بول
                    <br />
                    - مركز فتنس تايتن
                    <br />
                    - مسبح بيست
                    <br />
                    - مسبح كارا
                    <br />
                    - مركز لايف الرياضي
                    <br />
                    - باشا سبورت
                    <br />
                    - دانيا ستي
                    <br />
                    -دايك ستي
                    <br />
                    -شاري خونكان
                    <br />
                    - دريم لاند
                    <br />
                    - مسبح سالم
                    <br />
                    - مسبح السليمانية
                    <br />
                    - مركز بارادايس الرياضي
                    <br />
                    - سبورت سنتر
                    <br />
                    - باشا ستي
                    <br />
                    - أبراج تيتانيك
                    <br />
                    - نيجرل سبورت سنتر (حلبجة تازة)
                    <br />
                    - حلبجة الشهيد بول (حلبجة الشهيد)
                    <br />
                    - كيم لاند (حلبجة الشهيد)
                    <br />
                    - كركوك ستی
                    <br />
                    - مسبح شوريش (جمجمال)
                    <br />
                    - مسبح كاريزة (كلار)
                    <br />
                    - المركز الرياضي (كلار)
                    <br />
                    - مسبح صمود (صمود)
                    <br />
                    - مركز شاهانا الرياضي (سيدصادق)
                    <br />- مجمع سيدصادق الرياضي (سيدصادق)
                    <br />
                    <br />
                  </p>
                  <h2 className="top-title" style={{ fontSize: 25 }}>
                    العملاء الرئيسيين
                  </h2>
                  <br />
                  <p>
                    - كل المشاريع المنفذة اعلاه
                    <br />
                    - شاري جوان / فندق جراند ميلينيوم
                    <br />
                    - فندق اشور
                    <br />
                    - سبا كردستان
                    <br />
                    -مستشفى فاروق اف ام سي
                    <br />
                    - جامعة السليمانية
                    <br />
                    - دية ستي
                    <br />
                    - فندق رامادا
                    <br />
                    - قيوان ستي
                    <br />
                    - فندق روتانا
                    <br />
                    - جامعة كوية
                    <br />
                    - مسبح ميدلي
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
