import React from "react";

export default function Eng() {
  return (
    <>
      <div>
        <div>
          <div
            className="page-title-area bg-services-3"
            style={{
              backgroundImage: `url("../../assets/images/services/Construction/PCS/PSB.jpeg")`,
            }}
          >
            <div className="container">
              <div className="page-title-content">
                <h2>Construction and Installation Services</h2>
              </div>
            </div>
          </div>
          {/* End Page Title Area */}
          {/* Start Product Details Area */}
          <section className="services-details-area pt-100 pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="services-details-content">
                    <div className="services-contents">
                      <div className="services-details-one">
                        <img src="../assets/images/services/ins.jpg" alt="" />
                      </div>
                      <h2 style={{ color: "#129dd9" }}>
                        Great Design Requires Expert Execution
                      </h2>
                    </div>
                    <div className="services-contents">
                      <p>
                        POOLZONE is your only partner to implement your project
                        from Concept to Completion with a Competitive Cost. Our
                        build team is made up of highly qualified professionals
                        who are passionate about delivering the best result.
                      </p>
                      <p></p>
                    </div>
                    <div className="services-contents">
                      <h2>Swimming Pool</h2>
                      <p>
                        We are doing all pipework, filtration, and specialized
                        mechanical system and technical aspects of the indoor
                        and outdoor pools.
                      </p>
                      <p>
                        The construction of each swimming pool has its own
                        conditions according to the ground of the site, shape,
                        size, and some other factors, therefore it needs
                        scientific precision study, and special considerations
                        to install the pipework, and mechanical system.
                      </p>

                      <p>
                        For the best implementation results, and to get standard
                        and symmetrical works without problem or headache, let
                        one party construct your swimming pool as a turnkey
                        project … POOLZONE is your trusted partner to take
                        responsibility
                      </p>
                      <p style={{ paddingLeft: "25px" }}>
                        - Planning <br /> - Designing <br /> - Digging the
                        ground <br /> - Pipework (ground system)
                        <br /> - Civil works
                        <br /> - Cement rendering (cement plaster) <br /> -
                        Waterproofing treatment <br /> - Tiling <br /> - Balance
                        tank, Mechanical Room and mechanical system installation
                        <br /> - Finishing and accessories
                      </p>
                    </div>
                    <br />

                    <div className="services-contents">
                      <p
                        style={{
                          fontSize: 24,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        Mechanical works
                      </p>
                      <p>
                        All swimming pools are constructed and operated on the
                        same principle. In other words, regardless of the shape,
                        size, and additional features, the functioning of all
                        pools is basically similar. They all use a combination
                        of chemical treatment and filtration to keep the water
                        clean and healthy at all times.
                        <br />
                        <br />
                        Water treatment for swimming pools has two challenges:
                        <p style={{ paddingLeft: "25px" }}>
                          {" "}
                          - Hygiene for swimmers <br />- Clarity of the water
                        </p>
                      </p>
                      <p>
                        The purpose of swimming pool filtration is to treat
                        swimming pool water so that its chlorine and PH levels
                        are normal. It also works to remove unsafe elements such
                        as bacteria and algae to make the water safe for
                        everyone to enjoy in the pool.
                      </p>

                      <p>
                        The filtration system consists of several elements, each
                        of which plays a crucial role:
                        <br />
                        <p style={{ paddingLeft: "25px" }}>
                          <strong style={{ fontSize: 20 }}>
                            - Pump & electrical board:
                          </strong>
                          <p style={{ paddingLeft: "50px" }}>
                            <strong> - the pump</strong>: ensures
                            the circulation of water to recycle for
                            purification. It consists of 3 elements: a
                            pre-filter to eliminate coarse impurities, a “body”
                            consisting of a turbine that circulates the water
                            and a motor (single-phase or three-phase).
                            <br /> <strong>- the electrical board:</strong>
                             enables the connection of the pump to the
                            electrical circle source. It contains safety
                            elements (circuit-breaker) and a clock that enables
                            you to programme filtration time.
                            <br />
                            <strong>- programmable filtration clock</strong>: to
                            automatically trigger the filtration system.
                            Filtration time is proportionate to water
                            temperature.
                          </p>
                        </p>
                        <p style={{ paddingLeft: "25px" }}>
                          <strong style={{ fontSize: 20 }}>- Filters:</strong>
                           enable the separation of impurities (mineral and
                          organic) from the water that contains them. They
                          eliminate the turbidity of the water, making it clear
                          and improving its quality; Sand filters function by
                          passing dirt water through a sand filtration system.
                          The particles are trapped in the sand, and clean water
                          returns to the pool. You can also use glass media in
                          place of sand. Glass media filters filter better and
                          require less backwashing and serves for more years.
                        </p>
                        <p style={{ paddingLeft: "25px" }}>
                          <strong style={{ fontSize: 20 }}>
                            - The Hydraulic System:
                          </strong>
                          <p style={{ paddingLeft: "50px" }}>
                            <strong>- overflow system:</strong> Unlike skimmer
                            pools, in which the water bounces of the sides, an
                            overflow system keeps the water in constant motion:
                            the overflowing water passes into the channel that
                            surrounds the pool through a ceramic grid; this
                            water moves on to the balance tank and then the
                            filter to be later returned to the pool after
                            <br /> <strong>- piping:</strong> ensures the
                            connection between the technical facility and the
                            pool, it must resist high pressure.
                            <br />
                            <strong>- the refilling nozzle:</strong>: It ensures
                            the return of filtered water to your pool.
                          </p>
                        </p>
                        <p style={{ paddingLeft: "25px" }}>
                          <strong style={{ fontSize: 20 }}>
                            - Pool Heaters:{" "}
                          </strong>
                          Pool Heater, works by bringing water into a heating
                          tank and then pumping the warmed water back into the
                          pool.
                          <p style={{ paddingLeft: "50px" }}>
                            - Electric Heater
                            <br /> - Heat Pump
                            <br />- Gas Heater
                          </p>
                        </p>
                        <p style={{ paddingLeft: "25px" }}>
                          <strong style={{ fontSize: 20 }}>
                            - Control of Ambient Atmosphere by Dehumidification
                            Unit and Ventilation System Solutions:
                            <br />
                          </strong>
                          Air handling or environmental control systems are
                          essential on any indoor pool project to prevent
                          condensation and then costly damage to the pool hall.
                          It also ensures that the pool hall is a comfortable
                          environment for users and bathers.
                          <br />
                          <p style={{ paddingLeft: "50px" }}>
                            - <strong>Control indoor pool humidity: </strong>
                            Stabilize hall conditions through dehumidification.
                            <br /> -{" "}
                            <strong>Heat your indoor pool hall: </strong>
                            Recovering energy from the dehumidification process
                            will often heat an entire pool hall for the cost of
                            operating the dehumidifier alone.
                            <br /> -{" "}
                            <strong>Cool your indoor pool hall: </strong>An
                            optional remote condenser allows the DCA system to
                            cool the pool hall during the warm months.
                          </p>
                        </p>
                        <p style={{ paddingLeft: "25px" }}>
                          <strong style={{ fontSize: 20 }}>
                            - Pool Chemicals:&nbsp;
                          </strong>
                          Pool chemicals, such as chlorine and bromine, are
                          added to treated venues (for example, pools, hot
                          tubs/spas, and water playgrounds) to protect swimmers
                          from the spread of germs and prevent outbreaks. Other
                          pool chemicals help with the disinfection process (for
                          example, PH control), improve water quality, stop
                          corrosion and scaling of equipment, and protect
                          against algal growth. However, pool chemicals can
                          injure people when mixed together or when appropriate
                          personal protective equipment is not used when
                          handling them.
                          <br />
                          Our product range of chemicals comprises the following
                          types of products:
                          <p style={{ paddingLeft: "50px" }}>
                            - <strong>Disinfectants: </strong>chlorine-based
                            disinfectants and algaecides for hygiene. This
                            products in tablet, granular and liquid firm
                            provides continuous or shock chlorination.
                            <br /> - <strong>PH regulators: </strong>Alkaline
                            liquid products and inorganic acids in liquid and
                            powder form to regulate the PH value.
                            <br /> - <strong>Coagulant-flocculants: </strong>
                            iquid products which provides clear water by
                            percipitating the suspend solids, organic oils and
                            silicates that they can be eliminated in filters.
                            <br /> - Complementary chemicals for the
                            conservation of the pool in winter time. Prevention
                            of turbidity and scaling due to water hardness. For
                            the removal of scale, oil and other contaminations
                            we provide filter and surface cleaners.
                          </p>
                        </p>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="services-sidebar">
                    <div className="services-category">
                      <img
                        src="../../assets/images/services/Construction/PCS/PS1.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>

                    <div className="services-category">
                      <img
                        src="../../assets/images/services/Construction/PCS/PS2.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                  </div>
                  <div className="services-sidebar">
                    <div className="services-category">
                      <img
                        src="../../assets/images/services/Construction/PCS/PS3.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>

                    <div className="services-category">
                      <img
                        src="../../assets/images/services/Construction/PCS/PS4.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                  </div>
                  <div className="services-sidebar">
                    <div className="services-category">
                      <img
                        src="../../assets/images/services/Construction/PCS/PS5.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>

                    <div className="services-category">
                      <img
                        src="../../assets/images/services/Construction/PCS/PS6.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* End Product Area */}
      </div>
    </>
  );
}
