import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";

const productJson = require("../../../externals/products.json");

export default function Krd() {
  const [productlist, setProductlist] = useState([]);
  const [bannerImgName, setbannerImg] = useState("");

  const params = useParams();

  useEffect(() => {
    onBindProductsData();
  }, [params]);
  const onBindProductsData = () => {
    const list = [];
    productJson.forEach((item) => {
      if (item.subCategoryENG === params.id) list.push(item);
    });
    list ? setbannerImg(list[0].subCategoryIMG) : setbannerImg("");
    setProductlist(list);
  };

  return (
    <>
      <div>
        <div
          className="page-title-area bg-1"
          style={{
            backgroundImage: `url("../assets/images/products/${bannerImgName}.jpeg")`,
          }}
        >
          <div className="container">
            <div className="page-title-content">
              <h2>{productlist[0] ? productlist[0].subCategoryKrd : ""}</h2>
            </div>
          </div>
        </div>
        {/* End Page Title Area */}
        {/* Start Product Area */}
        <section className="product-area ptb-100">
          <div className="container">
            <div className="row">
              {productlist.map((item, pindex) => {
                return (
                  <div className="col-lg-3 col-sm-6">
                    <div key={pindex} className="single-product">
                      <Link
                        to={"/ProductDetails/" + item.pid}
                        className="product-img"
                      >
                        <img
                          src={
                            "../assets/images/products/" + item.img[0] + ".jpeg"
                          }
                          alt=""
                          style={{ width: "400px", height: "200px" }}
                        />
                      </Link>
                      <Link to={"/ProductDetails/" + item.pid}>
                        <h3>{item.productNameKrd}</h3>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        {/* End Product Area */}
      </div>
    </>
  );
}
