import React, { useState, useEffect } from "react";

export default function Arb() {
  return (
    <>
      <div dir="rtl">
        <div>
          <div className="page-title-area bg-services-5">
            <div className="container">
              <div className="page-title-content">
                <h2>خدمات الصيانة و القطع الغيار</h2>
              </div>
            </div>
          </div>
          {/* End Page Title Area */}
          {/* Start Product Details Area */}
          <section className="services-details-area pt-100 pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="services-details-content">
                    <div className="services-contents">
                      <div className="services-details-one">
                        <img src="../assets/images/services/main.jpg" alt="" />
                      </div>
                      <h2>المسابح المثالية تحتاج إلى اهتمام وعناية</h2>
                    </div>
                    <div className="services-contents">
                      <p>
                        نحن نقدم خدمات صيانة ودعم مستمرة استثنائية للحفاظ على
                        مسبحك والوحدات الأخرى نظيفة و فعالة.
                      </p>
                      <p>مجموعة متنوعة من الخدمات:</p>
                      <p style={{ paddingLeft: "25px" }}>
                        - إصلاح المشكلات و الخللات
                        <br /> - معالجة المياه
                        <br /> - قطع غيار
                        <br /> - اكسسوارات و المُكَمِّلات
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="services-sidebar">
                    <div className="services-category">
                      <img
                        src="../assets/images/services/MRS/MS1.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                  </div>
                  <div className="services-sidebar">
                    <div className="services-category">
                      <img
                        src="../assets/images/services/MRS/MS2.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* End Product Area */}
      </div>
    </>
  );
}
