import React from "react";

export default function Krd({ lan }) {
  return (
    <>
      <div>
        <div>
          <div
            className="page-title-area bg-services-3"
            style={{
              backgroundImage: `url("../../assets/images/services/Construction/FCS/FSB.jpeg")`,
            }}
          >
            <div className="container">
              <div className="page-title-content">
                <h2>خزمەتگوزاری دروستکردن و دامەزراندن</h2>
              </div>
            </div>
          </div>
          {/* End Page Title Area */}
          {/* Start Product Details Area */}
          <section className="services-details-area pt-100 pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="services-details-content">
                    <div className="services-contents">
                      <div className="services-details-one">
                        <img src="../assets/images/services/ins.jpg" alt="" />
                      </div>
                      <h2 style={{ color: "#129dd9" }}>
                        خزمەتگوزاری دروستکردن و دامەزراندن
                      </h2>
                    </div>
                    <div className="services-contents">
                      <p>
                        POOLZONE تەنها هاوبەشی تۆیە بۆ جێبەجێ کردنی پڕۆژەکەت لە
                        بیرۆکەوە بۆ تەواوکردن بە تێچووی کێبڕکێیانە. تیمی
                        بیناسازیمان لە کەسانی پسپۆڕی شارەزا پێکهاتووە کە خولیای
                        گەیاندنی باشترین ئەنجامیان هەیە.
                      </p>
                    </div>
                    <div className="services-contents">
                      <h2> نافورە</h2>
                      <p>
                        نافورە دەبێت دڵخۆشکەر و سەرسوڕهێنەر بێت، ئێمە ئەمانە
                        بەرجەستە دەکەین بە ڕێنیشاندان بە ئەندازیارەکانمان لەسەر
                        ئامێر و پرۆسەی بیناسازی و زیادکردنی هەر تەکنەلۆجیایەکی
                        نوێی جووڵەی پێشکەوتوو، بوخار و هەڵم، ڤیدیۆی ئەنیمەیشن،
                        مۆسیقای سەماکردن و ڕووناکی ئارامکەرەوە لە دەوری
                        نافورەکە. دەست پێ بکە لەگەڵ POOLZONE
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="services-sidebar">
                    <div className="services-category">
                      <img
                        src="../../assets/images/services/Construction/FCS/FS1.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>

                    <div className="services-category">
                      <img
                        src="../../assets/images/services/Construction/FCS/FS2.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* End Product Area */}
      </div>
    </>
  );
}
