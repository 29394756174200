// const loggedReducer = (state = false, action) => {
//   switch (action.type) {
//     case "SIGN_IN":
//       return !state;
//     default:
//       return state;
//   }
// };

// export default loggedReducer;
const languages = (state = {}, action) => {
  switch (action.type) {
    case "Arabic":
      return {
        // ...state,
        language: "Arabic",
        dir: "rtl",
      };
    case "English":
      return {
        // ...state,
        language: "English",
        dir: "ltr",
      };
    case "Kurdish":
      return {
        // ...state,
        language: "Kurdish",
        dir: "rtl",
      };
    default:
      return state;
  }
};

export default languages;
