import React from "react";

export default function Krd() {
  return (
    <>
      <div>
        <div>
          <div className="page-title-area bg-services-2">
            <div className="container">
              <div className="page-title-content">
                <h2>خزمەتگوزاری دیزاین و تەلارسازی</h2>
              </div>
            </div>
          </div>
          {/* End Page Title Area */}
          {/* Start Product Details Area */}
          <section className="services-details-area pt-100 pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="services-details-content">
                    <div className="services-contents">
                      <h2>
                        دیزاینکردن بە لەبەرچاو گرتنی خواست و پێداویستییەکانتان
                      </h2>
                    </div>
                    <div className="services-contents">
                      <p>
                        بەشی ئەندازیاریمان " ئەندازیارانی داهێنەر" تایبەتمەندە
                        لە دیزاین کردن بە لەبەرچاو گرتنی خواستەکانتان کە لەگەڵ
                        ئارەزوو و تێڕوانین و پێداویستییەکانتان بگونجێت بۆ
                        ماڵەکەت، ڤێلا یان پڕۆژە بازرگانیەکەت.
                      </p>
                      <p>
                        هەروەها سەرپەرشتیکردن و چاودێریکردنی جێبەجێکردنی پلان لە
                        شوێنی کارەکەدا لەلایەن ئەندازیارەکانمانەوە بۆ دڵنیابوون
                        لەوەی کە دروستکردن و دامەزراندنی دروستت بۆ ئەنجام دەدرێت
                        و هاوتایە لەگەڵ پلان و دیزاینەکەتدا.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="services-sidebar">
                    <div className="services-category">
                      <img
                        src="../assets/images/services/Design/DS1.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                  </div>
                  <div className="services-sidebar">
                    <div className="services-category">
                      <img
                        src="../assets/images/services/Design/DS2.jpeg"
                        alt=""
                        style={{
                          height: "auto",
                          width: "auto",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* End Product Area */}
      </div>
    </>
  );
}
