/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
import React, { useEffect } from "react";

const jsonData = require("../../../externals/images.json");

export default function Eng() {
  useEffect(() => {
    $(".gallery-popup").each(function () {
      $(this).magnificPopup({
        delegate: "a",
        type: "image",
        gallery: {
          enabled: true,
        },
      });
    });
  }, []);

  return (
    <>
      <div>
        <div>
          {/* Start Page Title Area */}
          <div className="page-title-area bg-1">
            <div className="container">
              <div className="page-title-content">
                <h2> Photo</h2>
              </div>
            </div>
          </div>
          {/* End Page Title Area */}
          {/* Start Gallery Area */}
          <section className="gallery-column-two gallery-popup ptb-100">
            <div className="container">
              <div className="row">
                {jsonData.map((item, pindex) => {
                  // if (filtered.length)
                  return (
                    <div className="col-lg-3 col-sm-6" key={item.id}>
                      <div className="single-gallery">
                        <img
                          src={
                            "../../../assets/images/gallery/photo/" + item.img
                          }
                          alt=""
                          style={{ height: 200 }}
                        />
                        <a
                          href={"../../assets/images/gallery/photo/" + item.img}
                          className="view-gallery"
                        >
                          <i className="bx bx-show-alt" />
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
          {/* End Gallery Area */}
        </div>
      </div>
    </>
  );
}
