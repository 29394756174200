import React from "react";

export default function Eng() {
  return (
    <>
      <div>
        <div
          className="page-title-area bg-1"
          style={{
            backgroundImage: `url("../assets/images/about/AboutBan.jpeg")`,
            // height: " 80vh",
            // backgroundPosition: "center",
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "center",
          }}
        >
          <div className="container">
            <div className="page-title-content">
              <h2>About Quality</h2>
            </div>
          </div>
        </div>

        {/* End Page Title Area */}
        {/* Start About Area */}
        <section className="about-area pt-100 pb-70">
          <div className="container">
            <div className="row ">
              <div className="col-lg-8">
                <div className="about-content">
                  <h2 className="top-title" style={{ fontSize: 25 }}>
                    About Quality
                  </h2>

                  <br />
                  <p>
                    In the field of construction of swimming pool, sauna,
                    jacuzzi, steam room, Turkish bath, fountain and aquatics,
                    there are mechanical, engineering and working
                    characteristics details and are implemented in several
                    stages, if not analyzed and studied in detail, and if its
                    implementation is not properly monitored, it is easy to say
                    that the swimming pool or the other facilities mentioned
                    above are not working properly, and it is very difficult to
                    solve in some projects. It is true that we can easily say
                    that your swimming pool is not working, but it is truer that
                    you have to destroy your swimming pool and rebuild it, and
                    we have many examples to prove this unpleasant and harmful
                    fact!
                    <br />
                    <br />
                    Therefore, understanding these details as a project owner
                    and deciding to implement your project by a company with
                    real experience (not just the number of working years) will
                    prevent you from wasting a lot of money and unnecessary
                    headaches. Pool Zone truly takes responsibility at every
                    stage until your project is handed over.
                    <br />
                    <br />
                    As a pool construction and supply company have to decide
                    what direction you are working in. Are you using very
                    high-quality equipment and supplies or low/medium quality?
                    <br />
                    <br />
                    POOLZONE has decided to work towards using high-quality
                    equipment and supplies, on the one hand, to be responsible
                    for not wasting any costs, small or large, and on the other
                    hand to avoid wasting time and headaches that then tire our
                    teams. After deciding to use very good quality equipment and
                    supplies, several other points should be taken into account,
                    as learning from mistakes and immediately correcting
                    mistakes, testing many products before using them, must
                    choosing factories and manufacturers that use high-quality
                    raw materials and new technology in manufacturing. This is
                    how POOLZONE proudly operates.
                    <br />
                    <br />
                    Not all high-quality equipment will work properly if it is
                    not carefully considered with the overall requirements and
                    specifics of your project.
                    <br />
                    <br />
                    POOLZONE is an authorized partner with:
                    <br />
                    <span style={{ marginLeft: "20px" }}>
                      - <strong>Hayward IMG (USA Brand)</strong>: has been
                      serving international distributors and builders in the
                      swimming pool and spa industry for the last 56 years.
                      Originally established as Meridian IMG, Hayward IMG offers
                      the complete line of residential and commercial swimming
                      pool products, including pumps, filters, heater, lights,
                      automation, chemical and chlorine feeders, drains, water
                      feature accessories, cleaners, solar equipment, valves,
                      skimmers, and associated parts from the United States.
                    </span>
                    <br />
                    <br />
                    <span style={{ marginLeft: "20px" }}>
                      - <strong>Kripsol (Spain Brand)</strong>: The brand
                      Kripsol ® is a global reference in the swimming pool
                      sector as well as being a leading company in water systems
                      for domestic, agricultural and industrial use and water
                      treatment. Currently, the Kripsol ® brand has three
                      production centers in Spain, which provide the market with
                      an extensive range of in-house products and a logistics
                      center for national and international distribution. This
                      enables us to optimize operations and be closer to our
                      customers in terms of logistics and aftersales service.
                    </span>
                    <br />
                    <br />
                    <span style={{ marginLeft: "20px" }}>
                      -{" "}
                      <strong>
                        Elecro Engineering Limited (Great Britain Brand)
                      </strong>
                      : Elecro established in 1997 is a UK leading manufacturer
                      and industry specialist in swimming pool, spa, splashers,
                      aquatic heating and disinfection products. It has grown
                      considerably, both through our excellent reputation for
                      producing outstanding products and forging lasting
                      relationships with respected clients, many of whom form
                      the core distributors for our products. Elecro
                      continuously research and develop new innovations and
                      technologies, utilizing cutting edge components. 
                    </span>
                  </p>
                  {/* <a href="about.html" className="default-btn">
                    <span>Read more</span>
                  </a> */}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="services-sidebar">
                  <div className="services-category">
                    <img
                      src="../../assets/images/AboutQ/AboutQ000.jpeg"
                      alt=""
                      style={{ height: "auto", width: "auto" }}
                    />
                  </div>{" "}
                  <div className="services-category">
                    <img
                      src="../../assets/images/AboutQ/AboutQ00.jpeg"
                      alt=""
                      style={{ height: "auto", width: "auto" }}
                    />
                  </div>{" "}
                  <div className="services-category">
                    <img
                      src="../../assets/images/AboutQ/AboutQ0.jpeg"
                      alt=""
                      style={{ height: "auto", width: "auto" }}
                    />
                  </div>
                  <div className="services-category">
                    <img
                      src="../../assets/images/AboutQ/AboutQ1.jpeg"
                      alt=""
                      style={{ height: "auto", width: "auto" }}
                    />
                  </div>
                </div>
                <div className="services-sidebar">
                  <div className="services-category">
                    <img
                      src="../../assets/images/AboutQ/AboutQ2.jpeg"
                      alt=""
                      style={{ height: "auto", width: "auto" }}
                    />
                  </div>

                  <div className="services-category">
                    <img
                      src="../../assets/images/AboutQ/AboutQ3.jpeg"
                      alt=""
                      style={{ height: "auto", width: "auto" }}
                    />
                  </div>
                </div>
                <div className="services-sidebar">
                  <div className="services-category">
                    <img
                      src="../../assets/images/AboutQ/AboutQ4.jpeg"
                      alt=""
                      style={{ height: "auto", width: "auto" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
