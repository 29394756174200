import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

export default function EngFooter() {
  return (
    <>
      <footer className="footer-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <Link to="/" className="logo">
                  <img src="../assets/img/pzlogow.png" alt="" />
                </Link>
                <p style={{ color: "white" }}>
                  Providing and installing swimming pool and aquatics, Ltd. is a
                  team composed of highly experienced and creative members and
                  engineers specialized in planning, designing, building, and
                  installing a luxury swimming pool, sauna, steam rooms, Jacuzzi
                  (hot pool), cold pool, Turkish bath (hamam) and fountain in
                  the Kurdistan Region and Iraq on a large scale.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3>Our services</h3>
                <ul className="import-link">
                  <li>
                    <Link to="/services/Consultancy">
                      {" "}
                      Consultancy Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/archs">
                      Design & Architectural Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/installion/main">
                      Construction and Installation Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/testing">
                      Test and Commissioning Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/maintenance">
                      {" "}
                      Maintenance & Replacement Part Services
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3>Quick link</h3>
                <ul className="import-link">
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/media/photo">Photo Gallery</Link>
                  </li>
                  <li>
                    <Link to="/media/video">Video Gallery</Link>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/poolzone1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://instagram.com/pool_zone?igshid=MzRlODBiNWFlZA=="
                      target="_blank"
                      rel="noreferrer"
                    >
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/@poolzone-dv4ve/videos"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Youtube
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3>Contact us</h3>
                <ul className="address">
                  <li className="location">
                    <i className="bx bxs-location-plus" />
                    <span style={{ color: "white" }}>
                      {" "}
                      Salim Street, Opposite to Sulaimaniyah Municipality, Near
                      Khasrawkhal Overpass
                      <br /> Sulaimaniyah City, Kurdistan Region, Iraq
                    </span>
                  </li>
                  <li>
                    <i className="bx bxs-envelope" />
                    <a href="mailto:info@poolzone.co">info@poolzone.co</a>
                  </li>
                  <li>
                    <i className="bx bxs-phone-call" />
                    <a href="tel:+964-(770)-333-5151">+964 (770) 333-5151</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
