import React, { useState, useEffect } from "react";

export default function Krd() {
  return (
    <>
      <div>
        <div>
          <div className="page-title-area bg-services-4">
            <div className="container">
              <div className="page-title-content">
                <h2>خزمەتگوزاری تێست کردن و کارپێکردن</h2>
              </div>
            </div>
          </div>
          {/* End Page Title Area */}
          {/* Start Product Details Area */}
          <section className="services-details-area pt-100 pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="services-details-content">
                    <div className="services-contents">
                      <div className="services-details-one">
                        <img src="../assets/images/services/test.jpg" alt="" />
                      </div>
                      <h2>خزمەتگوزاری تێست کردن و کارپێکردن</h2>
                    </div>
                    <div className="services-contents">
                      <p>
                        بۆ دڵنیابوونەوە لەوەی کە دامەزراندنێکی دروست ئەنجام
                        دەدەین بۆ پرۆژەکە وە لە بەرزترین ئاستدا، سەرپەرشتی و
                        چاودێری پرۆژەکە دەکەین لە گشت قۆناغەکانیدا وە بە تەواوی
                        وردەکاریەکانیەوە.
                      </p>
                      <p>
                        بۆ ڕادەستکردنی پرۆژەکە، تێست کردنی کۆتایی ئەنجام دەدەین،
                        فێرکردن و ڕاهێنان لەسەر چۆنیەتی بەکارهێنانی سیستمەکە.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="services-sidebar">
                    <div className="services-category">
                      <img
                        src="../assets/images/services/TCS/TCS1.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* End Product Area */}
      </div>
    </>
  );
}
