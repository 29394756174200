import React from "react";
import { Link } from "react-router-dom";

export default function Krd() {
  return (
    <>
      <div dir="rtl">
        <div
          className="page-title-area bg-1"
          style={{
            backgroundImage: `url("../assets/images/about/AboutBan.jpeg")`,
            // height: " 80vh",
            // backgroundPosition: "center",
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "center",
          }}
        >
          <div className="container">
            <div className="page-title-content">
              <h2>پوڵ زۆن</h2>
            </div>
          </div>
        </div>

        {/* End Page Title Area */}
        {/* Start About Area */}
        <section className="about-area pt-100 pb-70">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="about-img">
                  <img src="../../assets/img/about-img-2.png" alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-content">
                  <h2 className="top-title" style={{ fontSize: 25 }}>
                    کۆمپانیای POOLZONE
                  </h2>
                  <h4>
                    ئێمە کۆمپانیایەکی تایبەتمەندین بە خزمەتگوزاریی دروستکردن و
                    نۆژەنکردنەوەی مەلەوانگە
                  </h4>
                  <br />
                  <p>
                    بۆ دابینکردن و دامەزراندنی مەلەوانگە و کار و یارییە
                    ئاوییەکان... تیمێکە لە کارمەند و ئەندازیارانی بە ئەزموون و
                    داهێنەر پێکهاتووە کە تایبەتمەندن لە پلاندانان و دیزاینکردن و
                    دروستکردن و دامەزراندنی مەلەوانگە و ساونا و ژووری هەڵم و
                    جاکوزی (‌‌حەوزی گەرم) ، حەوزی سارد، حەمامی تورکی و نافورە لە
                    هەرێمی کوردستان و عێراق بە گشتی.
                    <br />
                    <br />
                    لە ساڵی ٢٠٠٣ەوە بە شێوەیەکی کارا کاردەکەین، وەڵامی کڕیاران
                    دەدەینەوە بۆ هەر داواکارییەک لە مەلەوانگەی نیشتەجێبوون و
                    بازرگانی بۆ خانوو، هۆتێل، پرۆژە گەشتیاریەکان، کۆشک، ڤێلا، و
                    دامەزراوەکانی تر، مەلەوانگەی داخراو و کراوە.
                    <br />
                    <br />
                    هەمیشە ئامانجمان پاراستنی پابەندبوونمانە بەرامبەر بە
                    دابینکردنی خزمەتگوزاریی و بەرهەمی کوالیتیە بەرزەکان، دانانی
                    بەها بەرامبەر نرخ، و لەسەدا سەد ڕازیبوونی کڕیار. <br />
                    <br />
                    هەروەها هەوڵ دەدەین ئاسۆمان فراوانتر بکەین بە فراوانکردنی
                    خزمەتگوزارییەکانمان بۆ گشت پێکهاتەکانی وەک مەلەوانگە، ساونا،
                    ژووری هەڵم، جاکوزی، حەوزی سارد، حەمامی تورکی، و نافورە ئەمەش
                    بەوە دەکرێت کە بە هیچ شێوەیەک لە کوالیتی کارکردن و
                    بەرهەمەکانمان کەم ناکەینەوە!
                    <br />
                    <br />
                    ڕاوێژکارەکانمان ڕێنمایی دروست و دڵنیاکەرەوە پێشکەش دەکەن
                    سەبارەت بەو کێشە چاوەڕواننەکراوانەی کە لە هەر قۆناغێکی
                    پڕۆژەکاندا دێنەپێش وە هەروەها دەربارەی پرۆسەکانی
                    چاککردنەوەش.
                    <br />{" "}
                  </p>
                  {/* <a href="about.html" className="default-btn">
                    <span>Read more</span>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="counter-area bg-color pt-50 pb-70" dir="rtl">
          <div className="container">
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-lg-3 col-sm-5">
                <div className="single-counter">
                  <h2>
                    <span className="odometer" data-count={900}>
                      ٢٠
                    </span>
                  </h2>
                  <h3>ساڵ ئەزمون</h3>
                </div>
              </div>
              <div className="col-lg-4 col-sm-5">
                <div className="single-counter">
                  <h2>
                    <span className="odometer" data-count={500}>
                      ٧
                    </span>
                  </h2>
                  <h3> هۆتێلە ٥ ئەستێرەکان</h3>
                </div>
              </div>
              <div className="col-lg-4 col-sm-5">
                <div className="single-counter">
                  <h2>
                    <span className="odometer" data-count={795}>
                      +٥٠
                    </span>
                  </h2>
                  <h3>پڕۆژەی مەڵەوانگە گشتیەکان</h3>
                </div>
              </div>
            </div>
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-lg-4 col-sm-5">
                <div className="single-counter">
                  <h2>
                    <span className="odometer" data-count={795}>
                      +٤٠٠
                    </span>
                  </h2>
                  <h3>پڕۆژەی مەلەوانگە تایبەتەکان</h3>
                </div>
              </div>
              <div className="col-lg-3 col-sm-5">
                <div className="single-counter">
                  <h2>
                    <span className="odometer" data-count={500}>
                      ١٠
                    </span>
                  </h2>
                  <h3>پارێزگا لەعێڕاق</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="shape counter-shape-1">
            <img src="../../assets/img/counter-shape-1.png" alt="" />
          </div>
          <div className="shape counter-shape-2">
            <img src="../../assets/img/counter-shape-2.png" alt="" />
          </div>
        </section>
      </div>
    </>
  );
}
