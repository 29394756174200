import React from "react";

export default function Ar({ lan }) {
  return (
    <>
      <div>
        <div>
          <div
            className="page-title-area bg-services-3"
            style={{
              backgroundImage: `url("../../assets/images/services/Construction/PCS/PSB.jpeg")`,
            }}
          >
            <div className="container">
              <div className="page-title-content">
                <h2>خدمات انشاء والتركيب</h2>
              </div>
            </div>
          </div>
          {/* End Page Title Area */}
          {/* Start Product Details Area */}
          <section className="services-details-area pt-100 pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="services-details-content">
                    <div className="services-contents">
                      <div className="services-details-one">
                        <img src="../assets/images/services/ins.jpg" alt="" />
                      </div>
                      <h2 style={{ color: "#129dd9" }}>خدمات انشاء والتركيب</h2>
                    </div>
                    <div className="services-contents">
                      <p>
                        POOLZONE هو شريكك الوحيد لتنفيذ مشروعك من الفكرة إلى
                        الإكمال بتكلفة تنافسية. يتكون فريق البناء لدينا من
                        محترفين مؤهلين تأهيلا عاليا ومتحمسين لتقديم أفضل
                        النتائج.
                      </p>
                    </div>
                    <div className="services-contents">
                      <h2>مسابح</h2>
                      <p>
                        نحن ننفذ جميع أعمال من تركيب الأنابيب (المنظومة الارضية)
                        والترشيح والاعمال الميكانيكية والتقنية للمسابح الداخلية
                        والخارجية.
                      </p>
                      <p>
                        بناء كل مسبح له شروطه الخاصة حسب أرضية الموقع وشكله
                        وحجمه وبعض العوامل الأخرى ، لذلك يحتاج إلى دراسة علمية
                        دقيقة واعتبارات خاصة للمنظومة الارضية و الانظمة
                        الميكانيكية.
                      </p>

                      <p>
                        للحصول على أفضل نتائج التنفيذ ، وللحصول على أعمال قياسية
                        ومتناسقة بدون مشاكل، اسمح لطرف واحد ببناء المسبحك كمشروع
                        تسليم مفتاح ... POOLZONE هو شريكك الموثوق به لتحمل
                        المسؤولية
                      </p>
                      <p style={{ paddingRight: "25px" }}>
                        - تخطيط
                        <br /> - تصميم
                        <br /> - حفر الأرض
                        <br /> - تركيب الأنابيب (المنظومة الارضية)
                        <br /> - الأعمال الخرسانية والمدنية
                        <br /> - جص الاسمنت
                        <br /> - معالجة تسرب المياه
                        <br /> - تبليط
                        <br /> - خزان التوازن للمياه وغرفة الميكانيكية وتركيب
                        الانظمة الميكانيكية
                        <br /> - - اكتمال واكسسوارات
                      </p>
                    </div>
                    <div className="services-contents">
                      <p
                        style={{
                          fontSize: 24,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        {" "}
                        الاعمال الميكانيكية
                      </p>
                      <p>
                        يتم إنشاء جميع المسابح وتشغيلها على نفس المبدأ بمعنى
                        آخر، بغض النظر عن الشكل والحجم والميزات الإضافية؛ أداء
                        جميع المسابح متشابه في الأساس. يستخدمون جميعًا مزيجًا من
                        المعالجة الكيميائية والترشيح للحفاظ على المياه نظيفة
                        وصحية في جميع الأوقات <br />
                        <br />
                        معالجة المياه المسابح لها تحديين:{" "}
                        <p style={{ paddingRight: "25px" }}>
                          {" "}
                          - النظافة للسباحين
                          <br />- صفاء الماء
                        </p>
                      </p>
                      <p>
                        الغرض من ترشيح المسابح هو معالجة مياه المسابح بحيث تكون
                        مستويات الكلور والحموضة فيها طبيعية. كما تعمل على إزالة
                        العناصر غير الآمنة مثل البكتيريا والطحالب لجعل المياه
                        آمنة للجميع ليستمتع بها في المسبح.
                      </p>

                      <p>
                        يتكون نظام الترشيح من عدة عناصر، يلعب كل منها دورًا
                        مهمًا:
                        <br />
                        <p style={{ paddingRight: "25px" }}>
                          <strong style={{ fontSize: 20 }}>
                            -المضخة و اللوحة الكهربائية:
                          </strong>
                          <p style={{ paddingRight: "50px" }}>
                            <strong> - المضخة:</strong>  تضمن تدوير المياه
                            لإعادة تدويرها من أجل التنقية. يتكون من 3 عناصر:
                            مرشح مسبق لإزالة الشوائب الخشنة، "جسم" يتكون من
                            توربين يدور الماء و"محرك" (أحادي الطور أو ثلاثي
                            الطور).
                            <br /> <strong>- اللوحة الكهربائية:</strong>  لتوصيل
                            المضخة بمصدر الدائرة الكهربائية. تمكنك من برمجة وقت
                            الترشيح لماء المسبح من خلال ساعة الترشيح القابلة
                            للبرمجة.
                            <br />
                            <strong>- برمجة عملية الاترشيح:</strong> لتشغيل نظام
                            الترشيح تلقائيًا. وقت الترشيح يتناسب مع درجة حرارة
                            الماء
                          </p>
                        </p>
                        <p style={{ paddingRight: "25px" }}>
                          <strong style={{ fontSize: 20 }}>
                            - المرشحات (الفلاتر):
                          </strong>
                            تمكن من فصل الشوائب (المعدنية والعضوية) عن المياه
                          التي تحتوي عليها. إنها تقضي على عكارة المياه وتجعلها
                          صافية وتحسن جودتها؛ تعمل الفلاتر الرملية عن طريق تمرير
                          المياه المتسخة من خلال نظام ترشيح الرمال الخاصة
                          (الرمال نقصد بالرمال الخاصة يستخدم لفلترة مياه
                          المسابح. الجسيمات يتحاصر في الرمال وتعود المياه
                          النظيفة إلى المسبح. يمكنك أيضًا استخدام الرمال
                          الزجاجية بدلاً من الرمل. تُرشح فلاتر الوسائط الزجاجية
                          بشكل أفضل وتتطلب قدرًا أقل من الغسيل العكسي وتعمل
                          لسنوات أكثر.
                        </p>
                        <p style={{ paddingRight: "25px" }}>
                          <strong style={{ fontSize: 20 }}>
                            - النظام الهيدروليكي:
                          </strong>
                          <p style={{ paddingRight: "50px" }}>
                            <strong> انظام اوفر فلو (التدفق الزائد):</strong>{" "}
                            على عكس نظام سكيمر، حيث يجري الماء من الجوانب، فإن
                            نظام التدفق الزائد يحافظ على الماء في حركة مستمرة،
                            يمر الماء الفائض إلى القناة التي تحيط بالمسبح؛ ينتقل
                            هذا الماء إلى خزان التوازن ثم يعاد الفلتر لاحقًا إلى
                            حوض السباحة بعد.
                            <br /> <strong>- منظومة الأنابيب:</strong> تضمن
                            الاتصال بين غرفة الميكانيك والمسبح، ويجب أن تقاوم
                            الضغط العالي.
                            <br />
                            <strong>- النوزلات لإعادة الماء:</strong>٣ يضمن عودة
                            المياه المفلترة إلى حوض السباحة .
                          </p>
                        </p>
                        <p style={{ paddingRight: "25px" }}>
                          <strong style={{ fontSize: 20 }}>
                            - سخانات مياه المسابح:{" "}
                          </strong>
                          سخان مياه المسبح، يعمل عن طريق جلب المياه إلى خزان
                          التدفئة ثم ضخ المياه الدافئة مرة أخرى في المسبح.
                          <p style={{ paddingRight: "50px" }}>
                            - سخانات الكهربائية
                            <br /> - سخانات المضخات الحرارة
                            <br />
                            -سخانات الغاز (غاز ال بي جي)
                          </p>
                        </p>
                        <p style={{ paddingRight: "25px" }}>
                          <strong style={{ fontSize: 20 }}>
                            - التحكم في الهواء المحيط بوحدة إزالة الرطوبة و نظام
                            التهوية:&nbsp;
                          </strong>
                          تعتبر أنظمة تحكم الهواء أو التحكم البيئي ضرورية في أي
                          مشروع مسبح داخلي لمنع التكثيف و الرطوبة ومن ثم منع
                          الأضرار المكلفة لقاعة المسبح. كما يضمن أن تكون قاعة
                          المسبح بيئة مريحة للمستخدمين.
                          <br />
                          <br />
                          <p style={{ paddingRight: "50px" }}>
                            -{" "}
                            <strong>
                              {" "}
                              التحكم في رطوبة قاعة المسبح الداخلي:&nbsp;
                            </strong>
                            قم بتثبيت ظروف القاعة من خلال إزالة الرطوبة.
                            <br /> -{" "}
                            <strong>تدفئة قاعة المسبح الداخلي:&nbsp; </strong>
                            غالبًا ما يؤدي استرداد الطاقة من عملية إزالة الرطوبة
                            إلى تدفئة قاعة المسبح بأكملها مقابل تكلفة تشغيل جهاز
                            إزالة الرطوبة وحده.
                            <br /> -{" "}
                            <strong> تبريد قاعة المسبح الداخلي:&nbsp; </strong>
                            يسمح المكثف بتبريد قاعة المسبح خلال الأشهر الدافئة.
                          </p>
                        </p>
                        <p style={{ paddingRight: "25px" }}>
                          <strong style={{ fontSize: 20 }}>
                            - كيماويات المسابح:&nbsp;
                          </strong>
                          تتم إضافة المواد الكيميائية الخاصة بالمسابح، مثل
                          الكلور (على سبيل المثال، المسابح، وأحواض المياه
                          الساخنة / المنتجعات الصحية، والالعاب المائية) لحماية
                          السباحين من انتشار الجراثيم ومنع تفشي المرض. تساعد
                          المواد الكيميائية الأخرى في المسابح في عملية التطهير
                          (على سبيل المثال، التحكم في درجة الحموضة)، وتحسين جودة
                          المياه، ووقف التآكل وقياس المعدات، والحماية من نمو
                          الطحالب.
                          <br />
                          تشمل مجموعة منتجاتنا من المواد الكيميائية الأنواع
                          التالية:
                          <p style={{ paddingRight: "50px" }}>
                            - <strong>المطهرات (كلور): </strong>المطهرات التي
                            تحتوي على الكلور ومبيدات الطحالب للنظافة. يتوفر هذه
                            المنتجات في شكل أقراص وحبيبات و سائلة الكلور.
                            <br /> -{" "}
                            <strong>
                              منظمات الأس الهيدروجيني بي اج:{" "}
                            </strong>{" "}
                            المنتجات القلوية والأحماض غير العضوية في سائلة و
                            حبيبات لتنظيم قيمة الرقم الهيدروجيني بي اج.
                            <br /> - <strong>مواد التخثر - الندف: </strong>{" "}
                            منتجات سائلة لتوفر مياه نقية عن طريق تجمع الغبار
                            الناعمة الصلبة المعلقة في الماء والزيوت العضوية
                            والسيليكات التي يمكن بعد التخلص منها في الفلاتر.
                            <br /> - المواد الكيميائية التكميلية للحفاظ على مسبح
                            في فصل الشتاء. منع التعكر والتقشر بسبب عسر الماء.
                            لإزالة الترسبات الكلسية والزيوت وغيرها من الملوثات.
                          </p>
                        </p>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="services-sidebar">
                    <div className="services-category">
                      <img
                        src="../../assets/images/services/Construction/PCS/PS1.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>

                    <div className="services-category">
                      <img
                        src="../../assets/images/services/Construction/PCS/PS2.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                    <div className="services-category">
                      <img
                        src="../../assets/images/services/Construction/PCS/PS3.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>

                    <div className="services-category">
                      <img
                        src="../../assets/images/services/Construction/PCS/PS4.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                    <div className="services-category">
                      <img
                        src="../../assets/images/services/Construction/PCS/PS5.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>

                    <div className="services-category">
                      <img
                        src="../../assets/images/services/Construction/PCS/PS6.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* End Product Area */}
      </div>
    </>
  );
}
