import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SwimmingPool from "./subs/SwimmingPool/SubAr";
import Souna from "./subs/Souna/SubAr";
import Jacuzzi from "./subs/Jacuzzi/SubAr";
import TurkishBath from "./subs/TurkishBath/SubAr";
import Fountain from "./subs/Fountain/SubAr";

export default function Arb() {
  const { subService } = useParams();

  function renderSubs() {
    if (subService === "swimmingpool") return <SwimmingPool />;
    else if (subService === "souna") return <Souna />;
    else if (subService === "jacuzzi") return <Jacuzzi />;
    else if (subService === "turkishbath") return <TurkishBath />;
    else if (subService === "fountain") return <Fountain />;
    else
      return (
        <>
          {" "}
          <div>
            <div>
              <div
                className="page-title-area bg-services-3"
                style={{
                  backgroundImage: `url("../../assets/images/services/Construction/PCS/PSB.jpeg")`,
                }}
              >
                <div className="container">
                  <div className="page-title-content">
                    <h2>خدمات انشاء والتركيب</h2>
                  </div>
                </div>
              </div>
              {/* End Page Title Area */}
              {/* Start Product Details Area */}
              <section className="services-details-area pt-100 pb-70">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8">
                      <div className="services-details-content">
                        <div className="services-contents">
                          <div className="services-details-one">
                            <img
                              src="../assets/images/services/ins.jpg"
                              alt=""
                            />
                          </div>
                          <h2 style={{ color: "#129dd9" }}>
                            خدمات انشاء والتركيب
                          </h2>
                        </div>
                        <div className="services-contents">
                          <p>
                            POOLZONE هو شريكك الوحيد لتنفيذ مشروعك من الفكرة إلى
                            الإكمال بتكلفة تنافسية. يتكون فريق البناء لدينا من
                            محترفين مؤهلين تأهيلا عاليا ومتحمسين لتقديم أفضل
                            النتائج.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            {/* End Product Area */}
          </div>
        </>
      );
  }
  return <>{renderSubs()}</>;
}
