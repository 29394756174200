import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

const productJson = require("../../../externals/products.json");

export default function Eng() {
  const [productDetails, setProductDetails] = useState();
  const params = useParams();

  useEffect(() => {
    onBindProductsData();
    window.$(".gallery-popup").each(function () {
      window.$(this).magnificPopup({
        delegate: "a",
        type: "image",
        gallery: {
          enabled: true,
        },
      });
    });
  }, [params]);
  const onBindProductsData = () => {
    const found = productJson.find((obj) => {
      return obj.pid === parseInt(params.id);
    });
    setProductDetails(found);
  };

  return (
    <>
      <div>
        <div>
          {/* <div className="page-title-area bg-1" style={{ backgroundImage: `url(../../../${productDetails.imgUrl} )`}}>
            <div className="container">
              <div className="page-title-content">
                <h2>Product details</h2>
              </div>
            </div>
          </div> */}
          {/* End Page Title Area */}
          {/* Start Product Details Area */}
          <br />
          <br />
          <br />
          <br />
          <br />

          <section className="product-details-area ptb-100">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12">
                  <div className="product-details-image">
                    <img
                      src={
                        productDetails
                          ? "../assets/images/products/" +
                            productDetails.img[0] +
                            ".jpeg"
                          : ""
                      }
                      alt=""
                      style={{
                        borderRadius: 15,
                        height: 300,
                        width: "auto",
                        objectFit: "fill",
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="product-details-desc">
                    <h3>
                      {productDetails ? productDetails.productNameENG : ""}
                    </h3>

                    <p className="category">
                      Category:{" "}
                      <span>
                        {productDetails ? productDetails.CategoryENG : ""}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="tab products-details-tab">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="tab_content">
                          <div className="tabs_item">
                            <div className="products-details-tab-content">
                              <h3 className="mb-2">Description</h3>
                              <p style={{ whiteSpace: "pre-wrap" }}>
                                {productDetails ? productDetails.DescEn : ""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <section className="gallery-column-two gallery-popup ptb-100">
                  <div className="container">
                    <div className="row">
                      {productDetails
                        ? productDetails.img.map((item, pindex) => {
                            // if (filtered.length)
                            return (
                              <div className="col-lg-3 col-sm-6" key={item.id}>
                                <div
                                  className="single-gallery"
                                  style={{
                                    height: 200,
                                    width: 200,
                                    borderRadius: 25,
                                  }}
                                >
                                  <img
                                    src={
                                      "../assets/images/products/" +
                                      item +
                                      ".jpeg"
                                    }
                                    alt=""
                                    style={{
                                      height: 200,
                                      width: 200,
                                      borderRadius: 25,
                                    }}
                                  />
                                  <a
                                    href={
                                      "../assets/images/products/" +
                                      item +
                                      ".jpeg"
                                    }
                                    className="view-gallery"
                                  >
                                    <i className="bx bx-show-alt" />
                                  </a>
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>

        {/* End Product Area */}
      </div>
    </>
  );
}
