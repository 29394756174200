import React from "react";
import { Link } from "react-router-dom";

export default function Arb() {
  return (
    <>
      <div>
        <div
          className="page-title-area bg-1"
          style={{
            backgroundImage: `url("../assets/images/about/AboutBan.jpeg")`,
            // height: " 80vh",
            // backgroundPosition: "center",
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "center",
          }}
        >
          <div className="container">
            <div className="page-title-content">
              <h2>بول زون</h2>
            </div>
          </div>
        </div>

        {/* End Page Title Area */}
        {/* Start About Area */}
        <section className="about-area pt-100 pb-70">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="about-img">
                  <img src="../../assets/img/about-img-2.png" alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-content">
                  <h2 className="top-title" style={{ fontSize: 25 }}>
                    شركة بول زون
                  </h2>
                  <h4>نحن شركة متخصصة في خدمات بناء وتجديد المسابح</h4>
                  <br />
                  <p>
                    لتوفير وتركيب المسابح والألعاب المائية المحدودة، هي فريق
                    مكون من أعضاء ذوي خبرة عالية ومبدعين ومهندسين متخصصين في
                    تخطيط وتصميم وبناء وتركيب مسابح فاخر وساونا وغرف بخار
                    وجاكوزي (حوض ساخن) ، حوض بارد ، حمام تركي ونافورة في إقليم
                    كوردستان والعراق على نطاق واسع.
                    <br />
                    <br />
                    نحن نعمل بكفاءة منذ عام 2003 ، ونستجيب للعملاء لأي استفسار
                    ومتطلبات للمسابح السكنية والتجارية للمنازل والفنادق
                    والمنتجعات والقصور والفيلات والمؤسسات الأخرى ، والمسابح
                    الداخلية والخارجية. نهدف دائمًا إلى الحفاظ على التزامنا تجاه
                    الخدمات والمنتجات عالية الجودة والقيمة مقابل المال ورضا
                    العملاء بنسبة مائة بالمائة.
                    <br />
                    <br />
                    نحاول أيضًا توسيع أفقنا من خلال توسيع خدماتنا في جميع
                    الأبعاد في المسابح والساونا وغرفة البخار والجاكوزي والحوض
                    البارد والحمام التركي والنافورة و ايضا الاعمال و الرياضات
                    المائية اخرى. و يتم تحقيق ذلك من خلال عدم تقليل عن جودة
                    عملنا ومنتجاتنا بأي شكل من الأشكال. <br />
                    <br />
                    يقدم مستشارونا المشورة السليمة والمطلوبة بشأن المشكلات غير
                    المتوقعة التي تنشأ مع تقدم المشاريع وعمليات الصيانة أيضًا.
                    <br />{" "}
                  </p>
                  {/* <a href="about.html" className="default-btn">
                    <span>Read more</span>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="counter-area bg-color pt-50 pb-70" dir="ltr">
          <div className="container">
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-lg-3 col-sm-5">
                <div className="single-counter">
                  <h2>
                    <span className="odometer" data-count={900}>
                      ٢٠
                    </span>
                  </h2>
                  <h3>سنة خبرة</h3>
                </div>
              </div>
              <div className="col-lg-4 col-sm-5">
                <div className="single-counter">
                  <h2>
                    <span className="odometer" data-count={500}>
                      ٧
                    </span>
                  </h2>
                  <h3> اوتيل خمس نجوم</h3>
                </div>
              </div>
              <div className="col-lg-4 col-sm-5">
                <div className="single-counter">
                  <h2>
                    <span className="odometer" data-count={795}>
                      +٥٠
                    </span>
                  </h2>
                  <h3>مشروع تجاري</h3>
                </div>
              </div>
            </div>
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-lg-4 col-sm-5">
                <div className="single-counter">
                  <h2>
                    <span className="odometer" data-count={795}>
                      +٤٠٠
                    </span>
                  </h2>
                  <h3>مشارع خاصة</h3>
                </div>
              </div>
              <div className="col-lg-3 col-sm-5">
                <div className="single-counter">
                  <h2>
                    <span className="odometer" data-count={500}>
                      ١٠
                    </span>
                  </h2>
                  <h3>محافظة في العراق</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="shape counter-shape-1">
            <img src="../../assets/img/counter-shape-1.png" alt="" />
          </div>
          <div className="shape counter-shape-2">
            <img src="../../assets/img/counter-shape-2.png" alt="" />
          </div>
        </section>
      </div>
    </>
  );
}
