import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

export default function KrdFooter() {
  return (
    <>
      <footer className="footer-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <Link to="/" className="logo">
                  <img src="../assets/img/pzlogow.png" alt="" />
                </Link>
                <p style={{ color: "white" }}>
                  بۆ دابینکردن و دامەزراندنی مەلەوانگە و کار و یارییە
                  ئاوییەکان... تیمێکە لە کارمەند و ئەندازیارانی بە ئەزموون و
                  داهێنەر پێکهاتووە کە تایبەتمەندن لە پلاندانان و دیزاینکردن و
                  دروستکردن و دامەزراندنی مەلەوانگە و ساونا و ژووری هەڵم و
                  جاکوزی (‌‌حەوزی گەرم) ، حەوزی سارد، حەمامی تورکی و نافورە لە
                  هەرێمی کوردستان و عێراق بە گشتی.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3>خزمەتگوزارییەکانمان</h3>
                <ul className="import-link">
                  <li>
                    <Link to="/services/Consultancy">
                      {" "}
                      خزمەتگوزاری ڕاوێژکاری
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/archs">
                      خزمەتگوزاری دیزاین و تەلارسازی
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/installion/main">
                      خزمەتگوزاری دروستکردن و دامەزراندن{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/testing">
                      خزمەتگوزاری تێست کردن و کارپێکردن{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/maintenance">
                      {" "}
                      خزمەتگوزاری چاککردنەوە و پارچەی یەدەک
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3>لینکی خێرا </h3>
                <ul className="import-link">
                  <li>
                    <Link to="/about"> دەربارەی ئێمە</Link>
                  </li>
                  <li>
                    <Link to="/media/photo">وێنە</Link>
                  </li>
                  <li>
                    <Link to="/media/video">ڤیدیۆ</Link>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/poolzone1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://instagram.com/pool_zone?igshid=MzRlODBiNWFlZA=="
                      target="_blank"
                      rel="noreferrer"
                    >
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/@poolzone-dv4ve/videos"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Youtube
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3>پەیوەندی</h3>
                <ul className="address">
                  <li className="location">
                    <i className="bx bxs-location-plus" />
                    <span style={{ color: "white" }}>
                      شەقامی سالم، بەرامبەر سەرۆکایەتی شارەوانی سلێمانی، نزیک
                      پردی خەسرەوخاڵ <br /> پارێزگای سلێمانی، هەرێمی کوردستان،
                      عێراق
                    </span>{" "}
                  </li>
                  <li>
                    <i className="bx bxs-envelope" />
                    <a href="mailto:info@poolzone.co">info@poolzone.co</a>
                  </li>
                  <li>
                    <i className="bx bxs-phone-call" />
                    <a href="tel:+964-(770)-333-5151"> 333-5151 (770) 964+</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
