import React from "react";

export default function Arb() {
  return (
    <>
      <div>
        <div
          className="page-title-area bg-1"
          style={{
            backgroundImage: `url("../assets/images/about/AboutBan.jpeg")`,
            // height: " 80vh",
            // backgroundPosition: "center",
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "center",
          }}
        >
          <div className="container">
            <div className="page-title-content">
              <h2>عن الجودة</h2>
            </div>
          </div>
        </div>

        {/* End Page Title Area */}
        {/* Start About Area */}
        <section className="about-area pt-100 pb-70">
          <div className="container">
            <div className="row ">
              <div className="col-lg-8">
                <div className="about-content">
                  <h2 className="top-title" style={{ fontSize: 25 }}>
                    عن الجودة
                  </h2>

                  <br />
                  <p>
                    في مجال بناء مسابح، ساونا، جاكوزي، غرفة البخار، حمام تركي،
                    نافورة اكواتيك، هناك تفاصيل ميكانيكية وهندسية وخصائص عمل
                    ويتم تنفيذها على عدة مراحل، إذا لم يتم تحليلها ودراستها
                    بالتفصيل، وإذا كانت كذلك لا تتم مراقبة التنفيذ بشكل صحيح،
                    فمن السهل القول أن مسبحك لا تعمل بشكل صحيح، ومن الصعب جدًا
                    حلها في بعض المشاريع. في هذه الحالة، صحيح أنه يمكننا أن نقول
                    بسهولة أن مسبحك لا تعمل، ولكن الأصح أنه عليك ترميم مسبحك
                    وإعادة بنائه، ولدينا العديد من الأمثلة التي تثبت هذه الحقيقة
                    غير السارة والضارة!
                    <br />
                    <br />
                    لذلك، فإن فهم هذه التفاصيل كصاحب مشروع واتخاذ قرار بتنفيذ
                    مشروعك من قبل شركة ذات خبرة حقيقية (وليس فقط عدد سنوات
                    العمل) سيمنعك من هدر الكثير من المال والصداع غير الضروري.
                    تتحمل POOLZONE المسؤولية في كل مرحلة حتى يتم تسليم مشروعك.
                    <br />
                    <br />
                    باعتبارك شركة إنشاء و تجهيز المسابح، يتعين عليك أن تقرر
                    الاتجاه الذي ستعمل فيه. هل تستخدم معدات و مستلزمات عالية
                    الجودة أم منخفضة/متوسطة الجودة؟
                    <br />
                    <br />
                    قررت شركة POOLZONE العمل على استخدام معدات و مستلزمات عالية
                    الجودة، من ناحية، لتكون مسؤولة عن عدم إهدار أي تكاليف، صغيرة
                    أو كبيرة، ومن ناحية أخرى لتجنب إضاعة الوقت والصداع الذي
                    يتعبنا بعد ذلك. بعد اتخاذ القرار باستخدام معدات ومستلزمات
                    ذات عالية الجودة، يجب أخذ عدة نقاط أخرى في الاعتبار،كتعلم من
                    الأخطاء وتصحيح الأخطاء فورًا، واختبارالمنتجات قبل استخدامها،
                    يجب اختيار المصانع الذين يستخدمون مواد خام عالية الجودة
                    وجديدة التكنولوجيا في التصنيع. هذه هي الطريقة التي تعمل بها
                    POOLZONE بفخر.
                    <br />
                    <br />
                    لن تعمل جميع المعدات عالية الجودة بشكل صحيح إذا لم يتم أخذها
                    في الاعتبار بعناية مع المتطلبات العامة وتفاصيل مشروعك ايضا.
                    <br />
                    <br />
                    POOLZONE شريك معتمد مع:
                    <br />
                    <span style={{ marginRight: "20px" }}>
                      - <strong>Hayward IMG علامة تجارية امريكية</strong>: تقدم
                      خدماتها للموزعين والبنائين الدوليين في مجال المسابح
                      والمنتجعات الصحية منذ 56 عامًا. تأسست Hayward IMG في الأصل
                      باسم Meridian IMG، وهي تقدم مجموعة كاملة من منتجات المسابح
                      السكنية والتجارية، بما في ذلك المضخات والمرشحات والسخان
                      والكلور والأجزاء المرتبطة بها من الولايات المتحدة.
                    </span>
                    <br />
                    <br />
                    <span style={{ marginRight: "20px" }}>
                      - <strong>Kripsol علامة تجارية أسبانية</strong>: تعتبر
                      العلامة التجارية Kripsol ® مرجعًا عالميًا في قطاع المسابح
                      بالإضافة إلى كونها شركة رائدة في أنظمة المياه للاستخدام
                      المنزلي والزراعي والصناعي ومعالجة المياه. حاليًا، تمتلك
                      العلامة التجارية Kripsol ® ثلاثة مراكز إنتاج في إسبانيا،
                      والتي تزود السوق بمجموعة واسعة من المنتجات الداخلية ومركز
                      لوجستي للتوزيع الوطني والدولي. يتيح لكريبسول و شركائه ذلك
                      تحسين العمليات وأن نكون أقرب إلى عملائنا من حيث الخدمات
                      اللوجستية وخدمة ما بعد البيع.
                    </span>
                    <br />
                    <br />
                    <span style={{ marginRight: "20px" }}>
                      -{" "}
                      <strong>
                        Elecro Engineering Limited العلامة التجارية البريطانية
                      </strong>
                      : تأسست شركة Elecro في عام 1997 وهي شركة تصنيع رائدة في
                      المملكة المتحدة ومتخصصة في صناعة المسابح، والمنتجعات
                      الصحية، والرشاشات، ومنتجات التدفئة والتطهير المائي. لقد
                      نمت بشكل كبير، سواء من خلال سمعتنا الممتازة في إنتاج
                      منتجات متميزة أو إقامة علاقات دائمة مع العملاء المحترمين،
                      الذين يشكل الكثير منهم الموزعين الأساسيين لمنتجاتنا. تقوم
                      شركة Elecro باستمرار بالبحث وتطوير الابتكارات والتقنيات
                      الجديدة باستخدام المكونات المتطورة. .
                    </span>
                  </p>
                  {/* <a href="about.html" className="default-btn">
                    <span>Read more</span>
                  </a> */}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="services-sidebar">
                  <div className="services-category">
                    <img
                      src="../../assets/images/AboutQ/AboutQ000.jpeg"
                      alt=""
                      style={{ height: "auto", width: "auto" }}
                    />
                  </div>{" "}
                  <div className="services-category">
                    <img
                      src="../../assets/images/AboutQ/AboutQ00.jpeg"
                      alt=""
                      style={{ height: "auto", width: "auto" }}
                    />
                  </div>{" "}
                  <div className="services-category">
                    <img
                      src="../../assets/images/AboutQ/AboutQ0.jpeg"
                      alt=""
                      style={{ height: "auto", width: "auto" }}
                    />
                  </div>
                  <div className="services-category">
                    <img
                      src="../../assets/images/AboutQ/AboutQ1.jpeg"
                      alt=""
                      style={{ height: "auto", width: "auto" }}
                    />
                  </div>
                </div>
                <div className="services-sidebar">
                  <div className="services-category">
                    <img
                      src="../../assets/images/AboutQ/AboutQ2.jpeg"
                      alt=""
                      style={{ height: "auto", width: "auto" }}
                    />
                  </div>

                  <div className="services-category">
                    <img
                      src="../../assets/images/AboutQ/AboutQ3.jpeg"
                      alt=""
                      style={{ height: "auto", width: "auto" }}
                    />
                  </div>
                </div>
                <div className="services-sidebar">
                  <div className="services-category">
                    <img
                      src="../../assets/images/AboutQ/AboutQ4.jpeg"
                      alt=""
                      style={{ height: "auto", width: "auto" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
