import React, { useEffect, useState } from "react";
import HeroSlider, { Overlay, Slide, MenuNav } from "hero-slider";
import Wrapper from "../../Components/bannerCom/Wrapper";
import Title from "../../Components/bannerCom/Title";
import Subtitle from "../../Components/bannerCom/Subtitle";
import { Link } from "react-router-dom";

const productData = require("../../externals/products.json");

export default function Eng() {
  const getRandomObject = (array, length) => {
    const randomObject = array[Math.floor(Math.random() * length)];
    return randomObject;
  };
  const [Product1, setProduct1] = useState(() =>
    getRandomObject(productData, productData.length)
  );
  const [Product2, setProduct2] = useState(() =>
    getRandomObject(productData, productData.length - 1)
  );
  const [Product3, setProduct3] = useState(() =>
    getRandomObject(productData, productData.length - 3)
  );

  useEffect(() => {
    window.$(".popup-youtube, .popup-vimeo").magnificPopup({
      disableOn: 300,
      type: "iframe",
      mainClass: "mfp-fade",
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,
    });
    window.$(".partner-slider").owlCarousel({
      loop: true,
      margin: 30,
      nav: false,
      dots: false,
      autoplay: true,
      smartSpeed: 1000,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 2,
        },
        414: {
          items: 3,
        },
        576: {
          items: 3,
        },
        768: {
          items: 4,
        },
        1200: {
          items: 5,
        },
      },
    });
  }, []);

  return (
    <>
      <div>
        <br />
        <br />
        <br />
        <br />
        <br />

        <HeroSlider
          height={"650px"}
          autoplay
          controller={{
            initialSlide: 1,
            slidingDuration: 500,
            slidingDelay: 100,
            onSliding: (nextSlide) =>
              console.debug("onSliding(nextSlide): ", nextSlide),
            onBeforeSliding: (previousSlide, nextSlide) =>
              console.debug(
                "onBeforeSliding(previousSlide, nextSlide): ",
                previousSlide,
                nextSlide
              ),
            onAfterSliding: (nextSlide) =>
              console.debug("onAfterSliding(nextSlide): ", nextSlide),
          }}
        >
          <Overlay>
            <Wrapper>
              <span
                className="top-title"
                style={{ color: "#ff8c00", fontSize: 25 }}
              >
                We are POOLZONE
              </span>
              <br />
              <br />
              <Title>
                The leader in quality pool services &amp; renovation
              </Title>
              <Subtitle>THE MEANING OF TRUST</Subtitle>
            </Wrapper>
          </Overlay>
          <Slide
            label="Swimming Pool"
            background={{
              backgroundImageSrc: "../assets/img/banner/1.jpeg",
              backgroundImageStyle: { objectFit: "fill" },
            }}
          />
          <Slide
            label="Sauna"
            background={{
              backgroundImageSrc: "../assets/img/banner/2.jpeg",
              backgroundImageStyle: { objectFit: "fill" },
            }}
          />
          <Slide
            label="Jacuzzi"
            background={{
              backgroundImageSrc: "../assets/img/banner/3.jpeg",
              backgroundImageStyle: { objectFit: "fill" },
            }}
          />
          <Slide
            label="Mechanical Room"
            background={{
              backgroundImageSrc: "../assets/img/banner/4.jpeg",
              backgroundImageStyle: { objectFit: "fill" },
            }}
          />{" "}
          <Slide
            label="Steam Room"
            background={{
              backgroundImageSrc: "../assets/img/banner/5.jpeg",
              backgroundImageStyle: { objectFit: "fill" },
            }}
          />
          <MenuNav />
        </HeroSlider>
        {/* End Banner Area */}
        {/* Stat Feature Area */}
        <div className="feature-area bg-color pt-100 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-sm-3 col-sm-6">
                <div className="single-feature">
                  <i className="flaticon-pool-cleaning" />
                  <h3>Consultancy Services</h3>
                  <p>
                    We conduct a comprehensive site survey and speak with you
                    about your every desire for your project installation before
                    any work begins
                  </p>
                </div>
              </div>
              <div className="col-sm-3 col-sm-6">
                <div className="single-feature">
                  <i className="flaticon-swimming-pool" />
                  <h3>Maintenance & Replacement Part Services</h3>
                  <p>
                    We offer exceptional ongoing maintenance and support
                    services to keep your pool and other facilities clean and
                    functional
                  </p>
                </div>
              </div>
              <div className="col-sm-3 col-sm-6 offset-sm-3 offset-lg-0">
                <div className="single-feature">
                  <i className="flaticon-swimming-pool-2" />
                  <h3>Design & Architectural Services</h3>
                  <p>
                    Our engineering department with creative engineers
                    specializes in bespoke design driven by your interests,
                    perceptions, and needs that is suited to your home, villa,
                    or commercial project
                  </p>
                </div>
              </div>

              <div className="col-sm-3 col-sm-6">
                <div className="single-feature">
                  <i className="flaticon-swimming-pool-3" />
                  <h3>Test and Commissioning Services</h3>
                  <p>
                    To ensure that we are doing the right setup of the project
                    and carried out at the highest standard, we will follow up
                    and partially test the project at each stage of
                    implementation and for every details
                  </p>
                </div>
              </div>
              <div className="col-sm-3 col-sm-6">
                <div className="single-feature">
                  <i className="flaticon-swimming-pool-4" />
                  <h3>Construction and Installation Services</h3>
                  <p>
                    For the best implementation results, and to get standard and
                    symmetrical works without problem or headache, let one party
                    construct your swimming pool as a turnkey project … POOLZONE
                    is your trusted partner to take responsibility
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Start About Area */}
        <section className="about-area pt-100 pb-70">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="about-img">
                  <img
                    src="../assets/img/home2.jpg"
                    alt=""
                    style={{ borderRadius: 15, height: "400px" }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-content">
                  <span className="top-title" style={{ fontSize: 30 }}>
                    Get Triple <strong style={{ fontSize: 40 }}> C</strong> with
                    POOLZONE
                  </span>
                  <h2>
                    Keep your pool water{" "}
                    <strong style={{ fontSize: 40, color: "#ff8c00" }}>
                      {" "}
                      C
                    </strong>
                    lean,{" "}
                    <strong style={{ fontSize: 40, color: "#ff8c00" }}>
                      {" "}
                      C
                    </strong>
                    lear{" "}
                    <strong style={{ fontSize: 40, color: "#ff8c00" }}>
                      {" "}
                      C
                    </strong>
                    ontinuously
                  </h2>
                  <p>
                    If you want to make your dream happened to swim in a clean,
                    clear pool continuously at the water temperature that you
                    like and without changing the water for a long term, get
                    started with POOLZONE
                  </p>
                  {/* <span href="about.html" className="default-btn">
                    <span>Read more</span>
                  </span> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End About Area */}
        {/* Start Partner Area */}
        <div className="partner-area bg-color ptb-100">
          <div className="container">
            <div className="partner-slider owl-theme owl-carousel">
              <div className="partner-item">
                <span href="#">
                  <img src="../../assets/img/partner/p-1.png" alt="" />
                </span>
              </div>
              <div className="partner-item">
                <span href="#">
                  <img src="../../assets/img/partner/p-2.png" alt="" />
                </span>
              </div>
              <div className="partner-item">
                <span href="#">
                  <img src="../../assets/img/partner/p-3.png" alt="" />
                </span>
              </div>
              <div className="partner-item">
                <span href="#">
                  <img src="../../assets/img/partner/p-4.png" alt="" />
                </span>
              </div>
              <div className="partner-item">
                <span href="#">
                  <img src="../../assets/img/partner/p-5.png" alt="" />
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* End Partner Area */}
        {/* Start Pool Covers Area */}
        <section className="pool-covers-area">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-lg-4 col-sm-6 p-0">
                <div className="pool-covers-item">
                  <img
                    src="../../assets/img/pool-covers/1.JPG"
                    alt=""
                    style={{ height: "399px", width: "100%" }}
                  />
                  <div className="pool-covers-content">
                    {/* <h3>
                      <span> Pool construction </span>
                    </h3> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 p-0">
                <div className="pool-covers-item" style={{ height: 399 }}>
                  <img
                    src="../../assets/img/pool-covers/2.JPG"
                    alt=""
                    style={{ height: "399px", width: "100%" }}
                  />
                  <div className="pool-covers-content">
                    {/* <h3>
                      <span> Pool maintenance </span>
                    </h3> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0 p-0">
                <div className="pool-covers-item">
                  <img
                    src="../../assets/img/pool-covers/3.JPG"
                    alt=""
                    style={{ height: "399px", width: "100%" }}
                  />
                  <div className="pool-covers-content">
                    {/* <h3>
                      <span> Pool covers </span>
                    </h3> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div>
          {/* Start Our Services Area */}
          <section className="our-services-area bg-color pt-100 pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-5"></div>
                <div className="col-lg-7">
                  <div className="row"></div>
                </div>
              </div>
            </div>
          </section>
          {/* End Our Services Area */}
          {/* Start Video Area */}
          <div className="video-area pb-100">
            <div className="container">
              <div className="video-content">
                <img
                  src="../../assets/images/gallery/Video/video-img.png"
                  alt=""
                />
                <div className="video-btn-2">
                  <a
                    href="https://www.youtube.com/watch?v=AGRbJQXllx0"
                    className="popup-youtube"
                  >
                    <span />
                    <span />
                    <span />
                    <span />

                    <i className="flaticon-play-button" />
                  </a>
                </div>
                <div className="shape video-shape-2">
                  <img src="../../assets/img/video/video-shape-2.png" alt="" />
                </div>
                <div className="shape video-shape-3">
                  <img src="../../assets/img/video/video-shape-3.png" alt="" />
                </div>
              </div>
            </div>
            <div className="shape video-shape-1">
              <img src="../../assets/img/video/video-shape-1.jpg" alt="" />
            </div>
          </div>
          {/* End Video Area */}

          {/* Start Product Area */}
          <section className="product-area pb-70">
            <div className="container">
              <div className="section-title">
                <span>Our latest equipment</span>
                <h2>We have the latest equipment</h2>
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-6">
                  <div className="single-product">
                    <Link
                      to={"/ProductDetails/" + Product1.pid}
                      className="product-img"
                    >
                      <img
                        src={
                          Product1
                            ? "../assets/images/products/" +
                              Product1.img[0] +
                              ".jpeg"
                            : ""
                        }
                        style={{
                          height: "250px",
                          width: "auto",
                          borderRadius: 15,
                        }}
                        alt=""
                      />
                    </Link>
                    <br />
                    <Link to={"/ProductDetails/" + Product1.pid}>
                      <h3>{Product1 ? Product1.productNameENG : ""}</h3>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <div className="single-product">
                    <Link
                      to={"/ProductDetails/" + Product2.pid}
                      className="product-img"
                    >
                      <img
                        src={
                          Product2
                            ? "../assets/images/products/" +
                              Product2.img[0] +
                              ".jpeg"
                            : ""
                        }
                        style={{
                          height: "250px",
                          width: "auto",
                          borderRadius: 15,
                        }}
                        alt=""
                      />
                    </Link>
                    <br />
                    <Link to={"/ProductDetails/" + Product2.pid}>
                      <h3>{Product2 ? Product2.productNameENG : ""}</h3>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                  <div className="single-product">
                    <Link
                      to={"/ProductDetails/" + Product3.pid}
                      className="product-img"
                    >
                      <img
                        src={
                          Product3
                            ? "../assets/images/products/" +
                              Product3.img[0] +
                              ".jpeg"
                            : ""
                        }
                        style={{
                          height: "250px",
                          width: "auto",
                          borderRadius: 15,
                        }}
                        alt=""
                      />
                    </Link>
                    <br />
                    <Link to={"/ProductDetails/" + Product3.pid}>
                      <h3>{Product3 ? Product3.productNameENG : ""}</h3>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="counter-area bg-color pt-50 pb-70">
            <div className="container">
              <div className="row" style={{ justifyContent: "center" }}>
                <div className="col-lg-3 col-sm-5">
                  <div className="single-counter">
                    <h2>
                      <span className="odometer" data-count={900}>
                        20
                      </span>
                    </h2>
                    <h3>Years Experience</h3>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-5">
                  <div className="single-counter">
                    <h2>
                      <span className="odometer" data-count={500}>
                        7
                      </span>
                    </h2>
                    <h3>5 Star Hotels</h3>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-5">
                  <div className="single-counter">
                    <h2>
                      <span className="odometer" data-count={795}>
                        50+
                      </span>
                    </h2>
                    <h3>Public Pool Projects</h3>
                  </div>
                </div>
              </div>
              <div className="row" style={{ justifyContent: "center" }}>
                <div className="col-lg-4 col-sm-5">
                  <div className="single-counter">
                    <h2>
                      <span className="odometer" data-count={795}>
                        400+
                      </span>
                    </h2>
                    <h3>Private Pool Projects</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-5">
                  <div className="single-counter">
                    <h2>
                      <span className="odometer" data-count={500}>
                        10
                      </span>
                    </h2>
                    <h3>Cities in Iraq</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="shape counter-shape-1">
              <img src="../../assets/img/counter-shape-1.png" alt="" />
            </div>
            <div className="shape counter-shape-2">
              <img src="../../assets/img/counter-shape-2.png" alt="" />
            </div>
          </section>
          {/* End Product Area */}
        </div>
      </div>
    </>
  );
}
