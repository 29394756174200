import React from "react";

export default function Eng() {
  return (
    <>
      <div>
        <div>
          <div className="page-title-area bg-services-5">
            <div className="container">
              <div className="page-title-content">
                <h2>Maintenance & Replacement Part Services</h2>
              </div>
            </div>
          </div>
          {/* End Page Title Area */}
          {/* Start Product Details Area */}
          <section className="services-details-area pt-100 pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="services-details-content">
                    <div className="services-contents">
                      <div className="services-details-one">
                        <img src="../assets/images/services/main.jpg" alt="" />
                      </div>
                      <h2>Perfect Pools Need Attention and Care</h2>
                    </div>
                    <div className="services-contents">
                      <p>
                        We offer exceptional ongoing maintenance and support
                        services to keep your pool and other facilities clean
                        and functional.
                      </p>
                      <p>A diverse variety of services:</p>
                      <p style={{ paddingLeft: "25px" }}>
                        - Fixing issues
                        <br /> - Water treatment
                        <br /> - Replacement parts
                        <br /> - Accessories
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="services-sidebar">
                    <div className="services-category">
                      <img
                        src="../assets/images/services/MRS/MS1.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                  </div>
                  <div className="services-sidebar">
                    <div className="services-category">
                      <img
                        src="../assets/images/services/MRS/MS2.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* End Product Area */}
      </div>
    </>
  );
}
