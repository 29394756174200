import React from "react";
import { Link } from "react-router-dom";

export default function Eng() {
  return (
    <>
      <div>
        <div
          className="page-title-area bg-1"
          style={{
            backgroundImage: `url("../assets/images/about/AboutBan.jpeg")`,
            // height: " 80vh",
            // backgroundPosition: "center",
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "center",
          }}
        >
          <div className="container">
            <div className="page-title-content">
              <h2>POOLZONE</h2>
            </div>
          </div>
        </div>

        {/* End Page Title Area */}
        {/* Start About Area */}
        <section className="about-area pt-100 pb-70">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="about-img">
                  <img src="../../assets/img/about-img-2.png" alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-content">
                  <h2 className="top-title" style={{ fontSize: 25 }}>
                    POOLZONE Company{" "}
                  </h2>
                  <h4>
                    We are a friendly and professional pool building services
                    &amp; renovation company
                  </h4>
                  <br />
                  <p>
                    providing and installing swimming pool and aquatics, Ltd. is
                    a team composed of highly experienced and creative members
                    and engineers specialized in planning, designing, building,
                    and installing a luxury swimming pool, sauna, steam rooms,
                    Jacuzzi (hot pool), cold pool, Turkish bath (hamam) and
                    fountain in the Kurdistan Region and Iraq on a large scale.
                    <br />
                    <br />
                    We are working efficiently since 2003, responding to
                    customers for any query and requirement of residential and
                    commercial pools for houses, hotels, resorts, palaces,
                    villas, and other institutions, indoor and outdoor pools. We
                    have always aimed at maintaining our commitment towards
                    top-quality services, quality products, value for money, and
                    a hundred percent customer satisfaction.
                    <br />
                    <br />
                    We also try to widen our horizon by expanding our services
                    at all dimensions like swimming pool, sauna, steam room,
                    jacuzzi, cold pool, Turkish bath, and fountain, and this is
                    achieved by keeping the quality of services and quality
                    products in any situation. <br />
                    <br />
                    Our consultants provide sound and secure advice on
                    unexpected issues that arise as the projects progresses and
                    the maintenance processes as well.
                  </p>
                  {/* <a href="about.html" className="default-btn">
                    <span>Read more</span>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End About Area */}
        {/* Start Our Services Area */}

        {/* End Our Services Area */}

        {/* Start Counter Area */}
        <section className="counter-area bg-color pt-50 pb-70">
          <div className="container">
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-lg-3 col-sm-5">
                <div className="single-counter">
                  <h2>
                    <span className="odometer" data-count={900}>
                      20
                    </span>
                  </h2>
                  <h3>Years Experience</h3>
                </div>
              </div>
              <div className="col-lg-4 col-sm-5">
                <div className="single-counter">
                  <h2>
                    <span className="odometer" data-count={500}>
                      7
                    </span>
                  </h2>
                  <h3>5 Star Hotels</h3>
                </div>
              </div>
              <div className="col-lg-4 col-sm-5">
                <div className="single-counter">
                  <h2>
                    <span className="odometer" data-count={795}>
                      50+
                    </span>
                  </h2>
                  <h3>Public Pool Projects</h3>
                </div>
              </div>
            </div>
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-lg-4 col-sm-5">
                <div className="single-counter">
                  <h2>
                    <span className="odometer" data-count={795}>
                      400+
                    </span>
                  </h2>
                  <h3>Private Pool Projects</h3>
                </div>
              </div>
              <div className="col-lg-3 col-sm-5">
                <div className="single-counter">
                  <h2>
                    <span className="odometer" data-count={500}>
                      10
                    </span>
                  </h2>
                  <h3>Cities in Iraq</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="shape counter-shape-1">
            <img src="../../assets/img/counter-shape-1.png" alt="" />
          </div>
          <div className="shape counter-shape-2">
            <img src="../../assets/img/counter-shape-2.png" alt="" />
          </div>
        </section>
      </div>
    </>
  );
}
