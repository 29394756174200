import React from "react";

export default function Krd() {
  return (
    <>
      <div>
        <div
          className="page-title-area bg-1"
          style={{
            backgroundImage: `url("../assets/images/about/AboutBan.jpeg")`,
            // height: " 80vh",
            // backgroundPosition: "center",
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "center",
          }}
        >
          <div className="container">
            <div className="page-title-content">
              <h2>دەربارەی کوالیتی</h2>
            </div>
          </div>
        </div>

        {/* End Page Title Area */}
        {/* Start About Area */}
        <section className="about-area pt-100 pb-70">
          <div className="container">
            <div className="row ">
              <div className="col-lg-8">
                <div className="about-content">
                  <h2 className="top-title" style={{ fontSize: 25 }}>
                    دەربارەی کوالیتی
                  </h2>

                  <br />
                  <p>
                    لە بواری دروست کردنی مەلەوانگە، ساونا، جاکوزی، ژووری هەڵم،
                    حەمامی تورکی، نافورە و ئەکواتیکدا وردەکاری زانستی میکانیکی و
                    ئەندازیاری و تایبەتمەندی کارکردن هەیە وە بە چەند قۆناغێک
                    جێبەجێ دەکرێت، ئەگەر لە هەر قۆناغێکدا زۆر بە وردی و
                    ئەندازەیی شیکاری و دیراسەی بۆ نەکرێت وە هەروەها جێبەجێ
                    کردنیشی چاودێری و بەدواداچوونی بۆ نەکرێت، ئەوا زۆر بە سانایی
                    دەتوانین بڵێین کە ئەو مەلەوانگەیە یاخود ئەو یەکانەی تریش کە
                    باس کران کاری خۆی ناکات، وە زۆر بە ئەستەم لە هەندێک پرۆژەدا
                    دەتوانرێت چارەسەر بکرێت. ڕاستە (بە سانایی) دەڵێین
                    مەلەوانگەکەت کاری خۆی ناکات، بەڵام ڕاست تر ئەوەیە کە دەبێت
                    مەلەوانگەکەت تێک بدەیتەوە و سەر لەنوێ دروستی بکەیتەوە،
                    نمونەشمان زۆرە بۆ سەلماندنی ئەو ڕاستیە ناخۆش و زەرەرمەندە!
                    <br />
                    <br />
                    بۆیە تێگەیشتنی تۆی بەڕێز وەک خاوەن پرۆژە لەو وردەکاریانە وە
                    بڕیاردان بۆ جێبەجێ کردنی لەلایەن کۆمپانیایەکی خاوەک ئەزموونی
                    ڕاستەقینە (نەک تەنها ژمارەی ساڵی کارکردن) بەدوورت دەگرێت لە
                    بەفیڕۆ چوونی تێچوویەکی زۆر وە سەرئێشەیەکی بێ مانا. پوڵ زۆن
                    بەڕاستی بەرپرسیاریەتی هەڵدەگرێت لە هەر قۆناغێکدا تاکو ڕادەست
                    کردنی پرۆژەکەت.
                    <br />
                    <br />
                    وەک کۆمپانیای بیناکار و دابینکردنی پێداویستیەکانی مەلەوانگە
                    دەبێت بڕیار بدەیت کە ئایا بە چ ئاراستەیەک کار دەکەیت، ئایا
                    ئامێر و پێداویستی کوالیتی زۆر بەرز بەکاردەهێنیت یاخود
                    کوالیتی نزم و مام ناوەند؟
                    <br />
                    <br />
                    پوڵ زۆن بڕیاری داوە بەو ئاراستەیە کار بکات کە ئامێر و
                    پێداویستی کوالیتی زۆر بەرز بەکار بهێنێت، لە لایەکەوە خۆمان
                    بە بەرپرسیار بزانین بەرامبەر بەفیڕۆنەدانی هەر تێچوویەک بچووک
                    بێت یاخود گەورە، وە لە هەمان کاتدا خۆ بەدوور گرتن لە کات
                    بەفیڕۆ دان و سەرئێشەیەک کە دواتر تیمەکانمان ماندوو دەکات.
                    پاش بڕیاردان لە بەکارهێنانی ئامێر و پێداویستی کوالیتی زۆر
                    باش، پێویستە چەند خاڵێکی تریش بە هەند وەربگیرێت، بە هەڵە و
                    فێربوون لە هەڵەکاندا تێپەڕیبێت و دەست بەجێ هەڵەکانی چاک
                    کردبێت، دەبێت زۆرێک لە بەرهەمەکانی تاقی کردبێتەوە پێش ئەوەی
                    بەکاریان بهێنێت، دەبێت چەند کارگە و دروستکەری هەڵبژاردبێت کە
                    مەوادی خامی کوالیتی بەرز و تەکنەلۆجیای نوێی بەکاربهێنێت لە
                    دروست کردندا. پۆڵ زۆن بە سەربەرزیەوە بەم شێوەیە کاردەکات.
                    <br />
                    <br />
                    مەرج نیە گشت ئامێر و پێداویستیەکی کوالیتی زۆر بەرزیش وەک
                    پێویست کاربکات گەر بەباشی دیراسە نەکرێت لەگەڵ کۆی گشتی
                    پێداویستیەکانی تردا وە هەروەها تایبەتمەندی پرۆژەکەت. <br />
                    <br />
                    پۆڵ زۆن هاوبەشی باوەڕپێکراوە لەگەڵ:
                    <br />
                    <span style={{ marginRight: "20px" }}>
                      - <strong>Hayward IMG ئەمریکی</strong>: هایوارد ئای ئێم جی
                      لە ماوەی ٥٦ ساڵی ڕابردوودا خزمەت بە دابەشکەران و
                      بیناکارانی نێودەوڵەتی دەکات لە پیشەسازی مەلەوانگە و سپا.
                      لە سەرەتادا بە ناوی Meridian IMG دامەزراوە، Hayward IMG
                      هێڵی تەواوی بەرهەمەکانی دروستکردنی مەلەوانگەی نیشتەجێبوون
                      و بازرگانی پێشکەش دەکات.
                    </span>
                    <br />
                    <br />
                    <span style={{ marginRight: "20px" }}>
                      - <strong>Kripsol ئیسپانی</strong>: مارکەی کریپسۆڵ ®
                      ناوێکی جیهانییە لە کەرتی مەلەوانگەدا هەروەها کۆمپانیایەکی
                      پێشەنگە لە سیستمی ئاو بۆ بەکارهێنانی ماڵ و بازرگانی و
                      کشتوکاڵی و پیشەسازی و چارەسەرکردنی ئاو.لە ئێستادا مارکەی
                      کریپسۆڵ ® سێ ناوەندی بەرهەمهێنانی لە ئیسپانیا هەیە، کە
                      کۆمەڵێک بەرهەمی ناوخۆیی بەرفراوان و ناوەندێکی لۆجستی بۆ
                      دابەشکردنی ناوخۆی ئیسپانیا و نێودەوڵەتی بۆ بازاڕ دابین
                      دەکەن. ئەمەش وامان لێدەکات کارەکانمان باشتر بکەین و لە
                      ڕووی لۆجستی و خزمەتگوزاری دوای فرۆشتنەوە لە کڕیارەکانمان
                      نزیکتر بین.
                    </span>
                    <br />
                    <br />
                    <span style={{ marginRight: "20px" }}>
                      - <strong>Elecro/Vulcan Engineering بەریتانی</strong>:
                      کۆمپانیای ئەندازیاری ئێلیکرۆ\ ڤولکان لیمیتد کە لە ساڵی
                      ١٩٩٧ دامەزراوە، کۆمپانیایەکی پێشەنگی بەرهەمهێنەر و
                      پیشەسازییە لە بەریتانیا لە بواری مەلەوانگە، سپا، سپلاشەر،
                      گەرمکەری ئاو و بەرهەمەکانی تەعقیمکردن. گەشەیەکی بەرچاوی
                      بەخۆیەوە بینیوە، چ لە ڕێگەی ناوبانگێکی نایاب بۆ
                      بەرهەمهێنانی بەرهەمی نایاب و دروستکردنی پەیوەندی بەردەوام
                      لەگەڵ کڕیارە بەڕێزەکان، کە زۆربەیان دابەشکەری سەرەکی بۆ
                      بەرهەمەکانمان پێکدەهێنن. ئێلیکرۆ\ ڤولکان بەردەوام
                      لێکۆڵینەوە دەکات و پەرە بە داهێنان و تەکنەلۆژیای نوێ
                      دەدات، بە بەکارهێنانی پێکهاتە پێشکەوتووەکان.
                    </span>
                  </p>
                  {/* <a href="about.html" className="default-btn">
                    <span>Read more</span>
                  </a> */}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="services-sidebar">
                  <div className="services-category">
                    <img
                      src="../../assets/images/AboutQ/AboutQ000.jpeg"
                      alt=""
                      style={{ height: "auto", width: "auto" }}
                    />
                  </div>
                  <div className="services-category">
                    <img
                      src="../../assets/images/AboutQ/AboutQ00.jpeg"
                      alt=""
                      style={{ height: "auto", width: "auto" }}
                    />
                  </div>
                  <div className="services-category">
                    <img
                      src="../../assets/images/AboutQ/AboutQ0.jpeg"
                      alt=""
                      style={{ height: "auto", width: "auto" }}
                    />
                  </div>

                  <div className="services-category">
                    <img
                      src="../../assets/images/AboutQ/AboutQ1.jpeg"
                      alt=""
                      style={{ height: "auto", width: "auto" }}
                    />
                  </div>
                </div>
                <div className="services-sidebar">
                  <div className="services-category">
                    <img
                      src="../../assets/images/AboutQ/AboutQ2.jpeg"
                      alt=""
                      style={{ height: "auto", width: "auto" }}
                    />
                  </div>

                  <div className="services-category">
                    <img
                      src="../../assets/images/AboutQ/AboutQ3.jpeg"
                      alt=""
                      style={{ height: "auto", width: "auto" }}
                    />
                  </div>
                </div>
                <div className="services-sidebar">
                  <div className="services-category">
                    <img
                      src="../../assets/images/AboutQ/AboutQ4.jpeg"
                      alt=""
                      style={{ height: "auto", width: "auto" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
