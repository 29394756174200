import React, { useState, useEffect } from "react";

export default function Krd() {
  return (
    <>
      <div>
        <div>
          <div className="page-title-area bg-services-5">
            <div className="container">
              <div className="page-title-content">
                <h2>خزمەتگوزاری چاککردنەوە و پارچەی یەدەک</h2>
              </div>
            </div>
          </div>
          {/* End Page Title Area */}
          {/* Start Product Details Area */}
          <section className="services-details-area pt-100 pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="services-details-content">
                    <div className="services-contents">
                      <div className="services-details-one">
                        <img src="../assets/images/services/main.jpg" alt="" />
                      </div>
                      <h2>
                        مەلەوانگە نمونەییەکان پێویستیان بە گرنگی پێدان و چاودێری
                        کردن هەیە
                      </h2>
                    </div>
                    <div className="services-contents">
                      <p>
                        ئێمە خزمەتگوزاری ناوازەی چاککردنەوە و پاڵپشتی بەردەوام
                        پێشکەش دەکەین بۆ ئەوەی مەلەوانگەکەت و یەکەکانی تر پاک و
                        کارا ڕابگرین.
                      </p>
                      <p>خزمەتگوزاری جۆراوجۆر:</p>
                      <p style={{ paddingLeft: "25px" }}>
                        - چاک کردنەوەی کێشەکان
                        <br /> -چاره‌سه‌ری پیسبوونی ئاو
                        <br /> - پارچەی یەدەک
                        <br /> - ئەکسسوارات و تەواوکاری
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="services-sidebar">
                    <div className="services-category">
                      <img
                        src="../assets/images/services/MRS/MS1.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                  </div>
                  <div className="services-sidebar">
                    <div className="services-category">
                      <img
                        src="../assets/images/services/MRS/MS2.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* End Product Area */}
      </div>
    </>
  );
}
