import React from "react";

export default function Eng() {
  return (
    <>
      <div>
        <div
          className="page-title-area bg-1"
          style={{
            backgroundImage: `url("../assets/images/about/AboutBan.jpeg")`,
            // height: " 80vh",
            // backgroundPosition: "center",
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "center",
          }}
        >
          <div className="container">
            <div className="page-title-content">
              <h2>References</h2>
            </div>
          </div>
        </div>

        {/* End Page Title Area */}
        {/* Start About Area */}
        <section className="about-area pt-100 pb-70">
          <div className="container">
            <div className="row ">
              <div className="col-lg-8">
                <div className="about-content">
                  <h2 className="top-title" style={{ fontSize: 25 }}>
                    Implemented Key Projects
                  </h2>

                  <br />
                  <p>
                    - Titanic Hotel <br />
                    - Mihrako Hotel <br />
                    - High Crest Hotel <br />
                    - Ramada Hotel (Sauna) <br />
                    - Atlantic Pool <br />
                    - High Class Center <br />
                    - Titan Pool <br />
                    - Titan Fitness Center <br />
                    - Best Pool <br />
                    - Kara Pool <br />
                    - Life Sport Center <br />
                    - Pasha Sport <br />
                    - Dania City <br />
                    - Daik City <br />
                    - Shari Xawnakan <br />
                    - Dream Land <br />
                    - Salim Pool <br />
                    - Slemani Pool <br />
                    - Paradise Sport Center <br />
                    - Sport Center <br />
                    - Pasha City <br />
                    - Titanic Towers <br />
                    - Natural Sport Pool (Halabjai Taza) <br />
                    - Halabjai Shahid Pool (Halabjai Shahid) <br />
                    - Game Yard (Halabjai Shahid) <br />
                    - Kirkuk City <br />
                    - Shorish Swimming Pool (Chamchamal) <br />
                    - Kareza Pool (Kalar) <br />
                    - Sport Center (Kalar) <br />
                    - Smood Swimming Pool (Smood) <br />
                    - Shahana Sport Center (Saidsadiq)
                    <br />- Saidsadiq Sport Camp (Saidsadiq)
                    <br />
                    <br />
                  </p>
                  <h2 className="top-title" style={{ fontSize: 25 }}>
                    Key Customers
                  </h2>
                  <br />
                  <p>
                    - All the implemented projects above
                    <br />
                    - Shari Jwan / Grand Millennium Hotel
                    <br />
                    - Ashur Hotel
                    <br />
                    - Kurdistan Spa
                    <br />
                    - FMC Hospital
                    <br />
                    - Sulaimaniyah University <br />
                    - Diya City
                    <br />
                    - Ramada Hotel
                    <br />
                    - Qaiwan City
                    <br />
                    - Rotana Hotel
                    <br />
                    - Koya University
                    <br />
                    - Middly Pool
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
