import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Arb from "./Arb";
import Eng from "./Eng";
import Krd from "./Krd";

export default function ProductList() {
  const Language = useSelector((state) => state.Language);

  function HeaderRender() {
    if (Language.language === "English") return <Eng />;
    else if (Language.language === "Kurdish") return <Krd />;
    else if (Language.language === "Arabic") return <Arb />;
  }
  return <>{HeaderRender()}</>;
}
