import React from "react";

export default function Arb() {
  return (
    <>
      <div dir="rtl">
        <div>
          <div className="page-title-area bg-services-2">
            <div className="container">
              <div className="page-title-content">
                <h2>خدمات التصميم والمعمارية</h2>
              </div>
            </div>
          </div>
          {/* End Page Title Area */}
          {/* Start Product Details Area */}
          <section className="services-details-area pt-100 pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="services-details-content">
                    <div className="services-contents">
                      <h2>خدمة تصميم تتناسب مع احاسيسك واحتياجاتك</h2>
                    </div>
                    <div className="services-contents">
                      <p>
                        يتخصص قسم الهندسة لدينا في التصميم حسب الطلب مدفوعًا
                        باهتماماتك وتصوراتك واحتياجاتك التي تناسب منزلك أو
                        مشروعك التجاري.
                      </p>
                      <p>
                        وأيضًا الإشراف والمتابعة على تنفيذ الخطة في الموقع من
                        قبل المهندسين للتأكد من حصولك على الانشاء و الإعداد
                        الصحيح لممتلكاتك وأن تكون مطابقًا للخطة والتصميم.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="services-sidebar">
                    <div className="services-category">
                      <img
                        src="../assets/images/services/Design/DS1.jpeg"
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                  </div>
                  <div className="services-sidebar">
                    <div className="services-category">
                      <img
                        src="../assets/images/services/Design/DS2.jpeg"
                        alt=""
                        style={{
                          height: "auto",
                          width: "auto",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* End Product Area */}
      </div>
    </>
  );
}
