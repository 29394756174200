import React from "react";
import { Link } from "react-router-dom";

export default function Krd() {
  return (
    <>
      <div>
        <div>
          {/* Start Page Title Area */}
          <div className="page-title-area bg-1">
            <div className="container">
              <div className="page-title-content">
                <h2>پەیوەندی کردن</h2>
              </div>
            </div>
          </div>
          {/* End Page Title Area */}
          {/* Start Contact Area */}
          <section className="main-contact-area ptb-100">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="contact-info">
                    {/* <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod.
                    </p> */}
                    <ul className="address">
                      <li className="location">
                        <i className="bx bxs-location-plus" />
                        <span>ناونیشان</span>
                        شەقامی سالم، بەرامبەر سەرۆکایەتی شارەوانی سلێمانی، نزیک
                        پردی خەسرەوخاڵ <br /> پارێزگای سلێمانی، هەرێمی کوردستان،
                        عێراق
                        <br /> 46001 As Sulaymaniyah, Iraq
                      </li>
                      <li>
                        <i className="bx bxs-phone-call" />
                        <span>تەلەفۆن</span>
                        <a href="tel:+964-(770)-333-5151">
                          333-5151 (770) 964+
                        </a>
                      </li>
                      <li>
                        <i className="bx bxs-envelope" />
                        <span>ئیمەیڵ</span>
                        <a href="info@poolzone.co">info@poolzone.co</a>
                      </li>
                    </ul>
                    <div className="sidebar-follow-us">
                      <h3>فۆڵۆومان بکەن:</h3>
                      <ul className="social-wrap">
                        {/* <li>
                          <a href="/" target="_blank">
                            <i className="bx bxl-twitter" />
                          </a>
                        </li> */}
                        <li>
                          <a
                            href="https://instagram.com/pool_zone?igshid=MzRlODBiNWFlZA=="
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="bx bxl-instagram" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.facebook.com/poolzone1"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="bx bxl-facebook" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.youtube.com/@poolzone-dv4ve/videos"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="bx bxl-youtube" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-8">
                  <div className="contact-wrap">
                    <div className="contact-form">
                      <div className="contact-title">
                        <h2>Ready to get started?</h2>
                      </div>
                      <form id="contactForm">
                        <div className="row">
                          <div className="col-lg-6 col-sm-6">
                            <div className="form-group">
                              <label>Name</label>
                              <input
                                type="text"
                                name="name"
                                id="name"
                                className="form-control"
                                required
                                data-error="Please enter your name"
                              />
                              <div className="help-block with-errors" />
                            </div>
                          </div>
                          <div className="col-lg-6 col-sm-6">
                            <div className="form-group">
                              <label>Email Address</label>
                              <input
                                type="email"
                                name="email"
                                id="email"
                                className="form-control"
                                required
                                data-error="Please enter your email"
                              />
                              <div className="help-block with-errors" />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group">
                              <label>Subject</label>
                              <input
                                type="text"
                                name="msg_subject"
                                id="msg_subject"
                                className="form-control"
                                required
                                data-error="Please enter your subject"
                              />
                              <div className="help-block with-errors" />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group">
                              <label>Message</label>
                              <textarea
                                name="message"
                                className="form-control"
                                id="message"
                                cols={30}
                                rows={10}
                                required
                                data-error="Write your message"
                                defaultValue={""}
                              />
                              <div className="help-block with-errors" />
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <button type="submit" className="default-btn">
                              <span>Send message</span>
                            </button>
                            <div
                              id="msgSubmit"
                              className="h3 text-center hidden"
                            />
                            <div className="clearfix" />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </section>
          {/* End Contact Area */}
          {/* Start Map Area */}
          <div className="map-area">
            <iframe
              title="poolzone address"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d405.706012850153!2d45.4072127!3d35.5623921!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40002f0ba4d161c5%3A0x9b05edee6ce8d751!2sPool%20Zone!5e0!3m2!1sen!2siq!4v1676924190727!5m2!1sen!2siq"
              width={600}
              height={450}
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </div>
    </>
  );
}
