import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ArHeader from "./ArHeader";
import EngHeader from "./EngHeader";
import KrdHeader from "./KrdHeader";

export default function Index() {
  const Language = useSelector((state) => state.Language);

  function HeaderRender() {
    if (Language.language === "English") return <EngHeader />;
    else if (Language.language === "Kurdish") return <KrdHeader />;
    else if (Language.language === "Arabic") return <ArHeader />;
  }
  return <>{HeaderRender()}</>;
}
